import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { auth0Audience, auth0ClientId, auth0SubDomain } from 'settings';

type Props = {
  children: React.ReactNode;
};

const Auth0ProviderWithHistory: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      audience={auth0Audience}
      clientId={auth0ClientId}
      domain={auth0SubDomain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
