import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from 'Components';

type Props = {
  component: React.ComponentType;
  exact?: boolean;
  path: string;
};

const ProtectedRoute: React.FC<Props> = ({ component, ...args }: Props) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <Loading />,
    })}
    {...args}
  />
);

export default ProtectedRoute;
