import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { Modal, ModalButtons } from 'Components';
import { Button } from 'app-components';
import { getStripeLink, sleep } from 'utils';
import { CircularProgress } from '@material-ui/core/';
import { useAppSelector } from 'hooks';
import { selectAccount } from 'features';

const WalletGenerator = () => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { logout, getAccessTokenSilently } = useAuth0();
  const account = useAppSelector(selectAccount);

  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    logout({ returnTo: window.location.origin });
  };

  const accGenerator = async (token: string, create: boolean) => {
    if (account.status === 'succeeded') {
      const { href } = window.location;
      const { account_id } = account.data;
      const response = await getStripeLink(
        href,
        account_id,
        'account_onboarding',
      );

      if (response.status === 200) {
        setError(false);
        sleep(3000).then(() => {
          window.location.href = response.data['url'];
          setProcessing(false);
        });
      } else {
        setError(true);
        setErrorMessage('There was an error navigating to Stripe');
        setProcessing(false);
      }
    } else {
      setError(true);
      setErrorMessage(
        'There was an error creating your Paperchain wallet, please try again and if error persists then contact support',
      );
      setProcessing(false);
    }
  };

  const handleStripe = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setError(false);
    setProcessing(true);
    sleep(account.status === 'succeeded' ? 3000 : 1000).then(() => {
      getAccessTokenSilently().then((token) => {
        if (account.status === 'succeeded') {
          setError(false);
          accGenerator(token, false);
        } else if (account.status === 'failed') {
          setError(true);
          setErrorMessage(
            'There was an error creating your Paperchain wallet, please try again and if error persists then contact support',
          );
          setProcessing(false);
        }
      });
    });
  };

  return (
    <FullPage>
      <Modal
        logo={true}
        showModal={true}
        closeHandler={handleLogout}
        heading='Welcome to your wallet setup'
      >
        <p>
          We've partnered with Stripe for the onboarding process and you'll be
          redirected to the account setup form. Once your application is
          approved (typically in minutes) your card will be sent out to you.
        </p>

        <span style={{ fontSize: '12px' }}>
          If you have any questions please chat with us at{' '}
          <a target='_blank' rel='noreferrer' href='https://paperchain.help'>
            paperchain.help
          </a>{' '}
          or email{' '}
          <a href='mailto:support@paperchain.io'>support@paperchain.io</a>{' '}
        </span>
        <ModalButtons>
          <Button onClick={handleLogout}>Logout</Button>
          <Button onClick={handleStripe}>
            {processing ? (
              <CircularProgress
                style={{
                  color: '#fff',
                  height: '20px',
                  width: '20px',
                  margin: '0 5px 0 0',
                }}
              />
            ) : (
              "Let's go!"
            )}
          </Button>
        </ModalButtons>
        {error && (
          <span
            style={{
              color: 'var(--color-red-err)',
              fontSize: '14px',
            }}
          >
            {errorMessage}
          </span>
        )}
      </Modal>
    </FullPage>
  );
};

const FullPage = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

export default WalletGenerator;
