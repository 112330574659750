import React from 'react';
import styled from 'styled-components';
import { CardLoader, Balance } from 'Components';

interface Props {
  balance?: number;
  balanceLabel?: string;
  cardNumber?: string;
  expire?: string;
  cvc?: string;
  showDetails?: boolean;
  revealId?: string;
  concealId?: string;
  loading?: boolean;
}
export const CreditCard: React.FC<Props> = ({
  balance,
  balanceLabel,
  cardNumber,
  cvc,
  expire,
  loading,
}: Props) => {
  if (loading) {
    return (
      <Container style={{ backgroundColor: 'transparent' }}>
        <CardLoader />
      </Container>
    );
  }

  return (
    <Container>
      <Logo src='https://storage.googleapis.com/pc-auth-assets/public/images/visa-logo.svg' />
      {balance != null && (
        <>
          <BalanceLabel>{balanceLabel}</BalanceLabel>
          <BalanceWrapper>
            <Balance>{Number(balance)}</Balance>
          </BalanceWrapper>
        </>
      )}
      {cardNumber && <CardNumber>{cardNumber}</CardNumber>}
      {expire && (
        <Expire>
          <p>Expires</p>
          <span>{expire}</span>
        </Expire>
      )}
      {cvc && (
        <Expire style={{ left: '35%' }}>
          <p>CVC</p>
          <span>{cvc}</span>
        </Expire>
      )}
    </Container>
  );
};

export const Container = styled.div`
  background-color: var(--color-black);
  border-radius: 1rem;
  color: var(--color-white);
  font-size: var(--font-size-body-lg);
  margin: auto;
  max-width: 381px;
  height: 236px;
  position: relative;
  width: 100%;
  @media screen and (max-width: 380px) {
    height: 190px;
    max-height: 190px;
    min-height: initial;
  }
`;

const Logo = styled.img`
  height: 20px;
  position: absolute;
  top: 88%;
  right: 35px;
  @media screen and (max-width: 380px) {
    top: 80%;
  }
`;

const CardNumber = styled.span`
  position: absolute;
  font-size: 1rem;
  letter-spacing: 3px;
  top: 55%;
  left: 10%;
  @media screen and (max-width: 380px) {
    letter-spacing: 2px;
  }
  @media screen and (max-width: 320px) {
    letter-spacing: 1.5px;
  }
`;

const BalanceLabel = styled.p`
  position: absolute;
  color: var(--color-grey-5);
  font-size: var(--font-size-base);
  top: 13%;
  left: 10%;
  @media screen and (max-width: 380px) {
    top: 12%;
  }
`;

const BalanceWrapper = styled.div`
  position: absolute;
  font-size: var(--font-size-h3);
  top: 19%;
  left: 10%;

  @media screen and (max-width: 992px) {
    font-size: calc(var(--font-size-h3) - 8px);
  }
  @media screen and (max-width: 500px) {
    font-size: calc(var(--font-size-h3) - 12px);
  }
`;

const Expire = styled.div`
  position: absolute;
  font-size: 1rem;
  top: 80%;
  left: 10%;
  p {
    color: var(--color-grey-5);
    font-size: 12px;
    margin: 0;
  }
  @media screen and (max-width: 380px) {
    top: 78%;
  }
`;

export default CreditCard;
