import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { puraApi } from 'settings';
import { RootState } from 'store';
import { Args, InitialState } from './accountTypes';

const initialState: InitialState = {
  status: 'idle',
  error: '',
  data: {
    CreatedAt: '',
    CreatedBy: '',
    account_id: '',
    details_filled: false,
    financial_account_id: '',
    id: -1,
    is_active: false,
    payee_id: '',
  },
};

export const fetchAccount = createAsyncThunk(
  'account/fetchAccount',
  async (args: Args) => {
    const response = await axios.get(
      `${puraApi}/payee/account/${args.userId}`,
      {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      },
    );
    return response.data.Data;
  },
);

interface UpdateArgs {
  token: string;
  userId: string;
  accountId: string;
}

export const updateDetails = createAsyncThunk(
  'account/updateDetails',
  async (args: UpdateArgs) => {
    const response = await axios.post(
      `${puraApi}/payee/${args.userId}/account/${args.accountId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      },
    );
    return response.data.Data;
  },
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    fetchAccount: (state, action) => {
      state.data = action.payload;
    },
    updateDetails: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchAccount.pending]: (state) => {
      state.status = 'loading';
    },
    // @ts-ignore
    [fetchAccount.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    // @ts-ignore
    [fetchAccount.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // @ts-ignore
    [updateDetails.pending]: (state) => {
      state.status = 'succeeded';
      state.data.details_filled = true;
    },
    // @ts-ignore
    [updateDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data.details_filled = true;
    },
    // @ts-ignore
    [updateDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.data.details_filled = true;
    },
  },
});

export const selectAccount = (state: RootState) => state.account;

export default accountSlice.reducer;
