import React from 'react';
import { Balance, Button, CardLoader, Section } from 'Components';
import { useGetInvoicesQuery } from '../../store/apis/remixApi';
import styled from 'styled-components';

interface Props {
  payeeAccountId: number;
}

function getDueText(dateInSeconds: number) {
  const date = new Date(dateInSeconds * 1000);
  date.setDate(date.getDate() - 1); // show a day before like Stripe does
  const dateString = date.toLocaleDateString('en-US', {
    year: undefined,
    month: 'long',
    day: 'numeric',
  });
  return `due ${dateString}`;
}

export default function Payback({ payeeAccountId }: Props) {
  const isAccountLoading = payeeAccountId === -1;
  const { data, isLoading } = useGetInvoicesQuery(payeeAccountId, {
    skip: isAccountLoading,
  });

  const pendingInvoice =
    data && data.find((invoice) => invoice.status === 'open');

  const amount = pendingInvoice ? pendingInvoice.amount_remaining : 0;

  return (
    <Section
      className='col-span-4 sm-col-span-full lg-col-span-6'
      style={{ height: '368px' }}
    >
      {isLoading || isAccountLoading ? (
        <CardLoader />
      ) : (
        <Container>
          <h1>Outstanding Balance</h1>
          <Description>
            The balance of instant payments already made on your royalties
          </Description>
          <>
            <AmountWrapper>
              <Balance isCents={true}>{amount}</Balance>
              {pendingInvoice && (
                <PaymentDue>{getDueText(pendingInvoice.due_date)}</PaymentDue>
              )}
            </AmountWrapper>
            {pendingInvoice && (
              <ButtonWrapper>
                <Button
                  href={pendingInvoice.hosted_invoice_url}
                  target='_blank'
                  rel='noreferrer'
                  style={{
                    backgroundColor: 'var(--color-green-6)',
                    color: 'var(--color-white)',
                  }}
                >
                  Make payment
                </Button>
              </ButtonWrapper>
            )}
          </>
        </Container>
      )}
    </Section>
  );
}

const PaymentDue = styled.div`
  color: var(--color-grey-6);
`;

const AmountWrapper = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: var(--font-size-h3);

  @media (max-width: 767px) {
    padding-top: 60px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 50px auto 1fr;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: auto;
`;

const Description = styled.div`
  color: var(--color-grey-6);
  font-size: 0.9rem;
`;
