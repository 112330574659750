import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'store';
import { notificationsApi } from 'settings';
import { InitialState } from './types';

const initialState: InitialState = {
  status: 'idle',
  error: null,
  notification: {
    emailStatus: true,
  },
};

interface Arguments {
  emailStatus?: boolean;
  token: string;
}

export const fetchNotification = createAsyncThunk(
  'notifications/fetchNotifications',
  async (args: Arguments) => {
    const response = await axios.get(`${notificationsApi}/notification`, {
      headers: {
        Authorization: `Bearer ${args.token}`,
      },
    });
    return response.data;
  },
);

export const updateNotification = createAsyncThunk(
  'notifications/updateNotifications',
  async (args: Arguments) => {
    const response = await axios.post(
      `${notificationsApi}/notification?emailStatus=${args.emailStatus}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      },
    );
    return response.data;
  },
);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotification: {
      reducer(state, action) {
        state.notification = action.payload;
      },
      prepare(payload) {
        return payload;
      },
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchNotification.pending]: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    // @ts-ignore
    [fetchNotification.fulfilled]: (state, action) => {
      state.error = '';
      state.status = 'succeeded';
      state.notification = action.payload;
    },
    // @ts-ignore
    [fetchNotification.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // @ts-ignore
    [updateNotification.fulfilled]: (state, action) => {
      state.notification = action.payload;
    },
  },
});

export const selectNotification = (state: RootState) => state.notification;

export const userNotificationReducer = notificationsSlice.reducer;
