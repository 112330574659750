import { configureStore } from '@reduxjs/toolkit';
import {
  accountReducer,
  cardsReducer,
  cardReferencesReducer,
  financialAccountReducer,
  transactionReducer,
  connectedAccountReducer,
  connectedAccountPersonsReducer,
  transferReducers,
  userNotificationReducer,
  authReducer,
} from 'features';
import remixApi from './apis/remixApi';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    auth: authReducer,
    cards: cardsReducer,
    cardReferences: cardReferencesReducer,
    connectedAccount: connectedAccountReducer,
    connectedAccountPersons: connectedAccountPersonsReducer,
    financialAccount: financialAccountReducer,
    notification: userNotificationReducer,
    transactions: transactionReducer,
    transfer: transferReducers,
    [remixApi.reducerPath]: remixApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(remixApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
