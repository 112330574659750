import { Transactions } from 'features/transactions/transactionTypes';
import React from 'react';
import { Transaction } from '../index';

interface Props {
  transaction: Transactions;
  previousDate: Date;
}

export const TransactionListRow: React.FC<Props> = ({
  transaction,
  previousDate,
}: Props) => {
  const merchant =
    transaction.flow_type === 'received_credit'
      ? transaction.description.split('|')[0]
      : transaction.description.split('|')[2];

  const date = new Date(transaction.created * 1000);
  return (
    <div>
      {date.getMonth() !== previousDate.getMonth() && (
        <h2>{date.toLocaleString('default', { month: 'long' })}</h2>
      )}
      <Transaction
        merchant={merchant}
        description={date.toDateString()}
        // @ts-ignore
        amount={parseFloat(transaction.amount) / 100}
        debit={transaction.flow_type === 'received_credit'}
        success={
          transaction.status === 'open' || transaction.status === 'posted'
        }
      />
    </div>
  );
};
