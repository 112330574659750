import React from 'react';
import ContentLoader from './ContentLoader';
import { IContentLoaderProps } from 'react-content-loader';

const CardLoader: React.FC<IContentLoaderProps> = (props) => {
  const height = props.height || '100%';
  const width = props.width || '100%';
  return (
    <ContentLoader width={width} height={height} {...props}>
      <rect rx='1rem' ry='1rem' width={width} height={height} />
    </ContentLoader>
  );
};

export default CardLoader;
