import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import {
  CollectionIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
  LibraryIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline';
import { NavLink } from 'Components';

interface navs {
  name: string;
  path: string;
  icon?: string;
}

interface Props {
  navigation: navs[];
}

export const BottomNav: React.FC<Props> = ({ navigation }: Props) => {
  const [path, setPath] = useState('');
  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <Container>
      {navigation.map((item, index) => (
        <MenuItem
          key={index}
          className={`menu-item ${path === item.path ? 'active' : ''}`}
        >
          <NavLink
            key={index}
            to={item.path}
            className='nav-button'
            color='inherit'
          >
            <NavIcon
              classes={`botnav-icon ${path === item.path ? 'bt-nav-act' : ''}`}
              icon={item.name}
            />
          </NavLink>
        </MenuItem>
      ))}
    </Container>
  );
};

interface NavIconProps {
  icon?: string;
  classes?: string;
}

const NavIcon: React.FC<NavIconProps> = ({ icon, classes }: NavIconProps) => {
  switch (icon) {
    case 'Cards':
      return <CreditCardIcon className={classes} />;
    case 'Integrations':
      return <ViewGridAddIcon className={classes} />;
    case 'Payments':
      return <CurrencyDollarIcon className={classes} />;
    case 'Transactions':
      return <CollectionIcon className={classes} />;
  }
  return <LibraryIcon className={classes} />;
};

const Container = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  position: fixed;
  float: left;
  width: 100%;
  height: 64px;
  display: none;
  z-index: 9999;

  color: var(--color-white);
  @media screen and (max-width: 800px) {
    display: flex;
    background-color: var(--color-black);
    min-height: 64px;
    max-height: 64px;
    bottom: 0;
  }
`;

const MenuItem = styled.span``;
