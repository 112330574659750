import React from 'react';
import {
  TransactionList,
  Section,
  EmptyViewPlaceholder,
  BulletListLoader,
} from 'Components';
import { useAppSelector } from 'hooks';
import { selectTransactions } from '../transactionsSlice';
import { RequestStatus, Transactions } from '../transactionTypes';

interface Props {
  transactions: Transactions[];
  status: RequestStatus;
}

export const RecentTransactions: React.FC<Props> = ({
  transactions,
  status,
}: Props) => {
  return (
    <Section
      className='col-span-4 lg-col-span-6 sm-col-span-full'
      style={{ minHeight: '50vh' }}
    >
      {(status === 'loading' || status === 'idle') && (
        <>
          <h1>Recent Transactions</h1>
          <BulletListLoader />
        </>
      )}

      {status === 'succeeded' && transactions.length === 0 && (
        <EmptyViewPlaceholder
          heading='Recent Transactions'
          description='Your recent transactions will be shown here'
        />
      )}

      {status === 'succeeded' && transactions.length > 0 && (
        <>
          <h1>Recent Transactions</h1>
          <TransactionList transactions={transactions.slice(0, 10)} />
        </>
      )}
    </Section>
  );
};

const ConnectedRecentTransactions: React.FC = () => {
  const { transactions, status } = useAppSelector(selectTransactions);
  return <RecentTransactions transactions={transactions} status={status} />;
};

export default ConnectedRecentTransactions;
