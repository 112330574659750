import React from 'react';
import ContentLoader from './ContentLoader';
import { IContentLoaderProps } from 'react-content-loader';

const ListLoader: React.FC<IContentLoaderProps> = (props) => {
  const rowHeight = 60;
  const width = props.width || '100%';
  const height = typeof props.height === 'number' ? props.height : 400;
  if (typeof props.height === 'string') {
    console.warn(
      'ListLoader accepts only numeric height prop in order to easily calculate rows.',
    );
  }

  function renderRow(index: number) {
    const y = index * rowHeight + 20;
    return (
      <React.Fragment key={index}>
        <rect x='0' y={y} rx='3' ry='3' width={width} height='10' />
        <rect
          x='30'
          y={y + 20}
          rx='3'
          ry='3'
          width={`calc(${width} - 50px)`}
          height='10'
        />
        <rect
          x='20'
          y={y + 40}
          rx='3'
          ry='3'
          width={`calc(${width} - 70px)`}
          height='10'
        />
      </React.Fragment>
    );
  }

  function renderRows() {
    const numberOfRows = Math.floor(height / rowHeight);
    const rows = [];

    for (let i = 0; i < numberOfRows; i++) {
      rows.push(renderRow(i));
    }
    return <>{rows}</>;
  }

  return (
    <ContentLoader width={width} height={height}>
      {renderRows()}
    </ContentLoader>
  );
};

export default ListLoader;
