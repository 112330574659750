import React, { useEffect } from 'react';
import { Auth, NotAValidLink } from 'Views';
import { ProtectedRoute } from 'auth';
import { Route, Switch } from 'react-router-dom';
import { environment, heapKey } from 'settings';
import { loadHeap } from 'heap';
import WithNavbar from '../WithNavbar';
import VerifyBanking from '../VerifyBanking';

const App: React.FC = () => {
  useEffect(() => {
    window.addEventListener('load', () => {
      if (environment.toLocaleLowerCase() === 'production') {
        loadHeap(heapKey);
      }
    });
  });

  return (
    <Switch>
      <Route path='/auth/' component={Auth} />
      <ProtectedRoute
        path='/verifybanking/:account_id'
        component={VerifyBanking}
      />
      <ProtectedRoute path='/' component={WithNavbar} />
      <Route path='*' component={NotAValidLink} />
    </Switch>
  );
};

export default App;
