import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAccount } from 'utils';
import { RootState } from 'store';
import { Args } from './accTypes';
import { initialState } from './init';

export const fetchConnectedAccount = createAsyncThunk(
  'connectedAccount/fetchConnectedAccount',
  async (args: Args) => {
    const response = await getAccount(args.id, args.token);
    return response.data;
  },
);

const connectedAccountSlice = createSlice({
  name: 'connectedAccount',
  initialState,
  reducers: {
    fetchConnectedAccount: (state, action) => {
      state.data = action.payload.Data;
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchConnectedAccount.pending]: (state) => {
      state.status = 'loading';
    },
    // @ts-ignore
    [fetchConnectedAccount.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    // @ts-ignore
    [fetchConnectedAccount.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectConnectedAccount = (state: RootState) =>
  state.connectedAccount;

export default connectedAccountSlice.reducer;
