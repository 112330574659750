import { InitialState } from './accTypes';

export const initialState: InitialState = {
  status: 'idle',
  error: null,
  persons: {
    object: 'list',
    data: [
      {
        account: '',
        address: {
          city: '',
          country: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: '',
        },
        created: 0,
        dob: {
          day: 0,
          month: 0,
          year: 0,
        },
        email: '',
        first_name: '',
        id: '',
        id_number_provided: false,
        last_name: '',
        object: '',
        phone: '',
        relationship: {
          director: false,
          executive: false,
          owner: false,
          percent_ownership: 0,
          representative: false,
          title: '',
        },
        ssn_last_4_provided: false,
        verification: {
          additional_document: {
            back: '',
            details: '',
            details_code: '',
            front: '',
          },
          details: '',
          details_code: '',
          document: {
            back: '',
            details: '',
            details_code: '',
            front: '',
          },
        },
      },
    ],
    has_more: false,
    url: '',
  },
};
