import React, { useState } from 'react';
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import SignUp from './signup';
import WelcomePage from './welcome-page';
import { Loading } from 'Components';

interface Props {
  welcome: string;
  signUp: string;
}

const Auth: React.FC<Props> = ({ welcome, signUp }: Props) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  const { path } = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(false);

  if (isAuthenticated) {
    history.push('/');
  }

  if (isAuthenticated) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route path={`${path}${welcome}/:id`}>
        <WelcomePage
          error={error}
          loading={loading}
          notFound={notFound}
          setError={setError}
          setLoading={setLoading}
          setNotFound={setNotFound}
        />
      </Route>
      <Route path={`${path}${signUp}`} component={SignUp} />
    </Switch>
  );
};

Auth.defaultProps = {
  welcome: 'invite',
  signUp: 'sign-up',
};

export default Auth;
