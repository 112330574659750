import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { bankingApi } from 'settings';
import { RootState } from 'store';
import { stripeOpts } from 'utils';

import { Args, InitialState } from './transactionTypes';

export const fetchTransactions = createAsyncThunk(
  'account/fetchTransactions',
  async (args: Args) => {
    stripeOpts.headers['Stripe-Account'] = args.connAcc;
    const response = await axios.get(
      `${bankingApi}/${args.connAcc}/transactions?financial_account=${args.finAcc}`,
      {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      },
    );
    return response.data.data;
  },
);

const initialState: InitialState = {
  status: 'idle',
  error: null,
  transactions: [],
};

const transactionsSlice = createSlice({
  name: 'tranactions',
  initialState,
  reducers: {
    fetchTransactions: (state, action) => {
      state.transactions = action.payload.Data;
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchTransactions.pending]: (state) => {
      state.status = 'loading';
    },
    // @ts-ignore
    [fetchTransactions.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.transactions = action.payload;
    },
    // @ts-ignore
    [fetchTransactions.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const selectTransactions = (state: RootState) => state.transactions;

export default transactionsSlice.reducer;
