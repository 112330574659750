import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Home, Payments, Transactions, AccountSettings } from 'Views';
import { BottomNav, NavBar } from 'Components';
import { NotAValidLink } from 'Views';
import { Footer } from 'app-components';
import { nav, bottomNavs } from 'data';
import { VerifyEmail } from 'TemplateParts';
import { loadSupportInProduction } from 'intercom';
import { WalletGenerator } from 'Containers';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Cards,
  fetchAccount,
  fetchCardReferences,
  fetchCards,
  fetchConnectedAccount,
  fetchConnectedAccountPersons,
  fetchNotification,
  fetchFinancialAccount,
  fetchTransactions,
  selectAccount,
  selectAccessToken,
  selectFinancialAccount,
  selectCardReferences,
  selectCards,
  selectConnectedAccount,
  selectConnectedAccountPersons,
  selectNotification,
  selectTransactions,
  updateDetails,
} from 'features';
import { setAccessToken } from '../../features/auth';

const WithNavbar: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  // Redux Selectors
  const account = useAppSelector(selectAccount);
  const financialAccount = useAppSelector(selectFinancialAccount);
  const cardReferences = useAppSelector(selectCardReferences);
  const cards = useAppSelector(selectCards);
  const transactions = useAppSelector(selectTransactions);
  const connAccount = useAppSelector(selectConnectedAccount);
  const connAccountPersons = useAppSelector(selectConnectedAccountPersons);
  const notifications = useAppSelector(selectNotification);
  const accessToken = useAppSelector(selectAccessToken);

  const dispatch = useAppDispatch();

  function init(token: string) {
    dispatch(setAccessToken(token));
  }

  getAccessTokenSilently().then(init).catch(console.log);

  React.useEffect(() => {
    if (!accessToken) {
      return;
    }
    if (account.data.is_active && transactions.status === 'idle') {
      dispatch(
        fetchTransactions({
          connAcc: account.data.account_id,
          finAcc: account.data.financial_account_id,
          token: accessToken,
        }),
      );
    }
  }, [accessToken, account, dispatch, transactions.status]);

  useEffect(() => {
    const loadData = async (token: string) => {
      if (account.status === 'idle') {
        await dispatch(fetchAccount({ token: token, userId: user.sub }));
      }

      if (account.status === 'succeeded') {
        const { account_id, financial_account_id } = account.data;

        // Fetch FinancialAccount
        if (financialAccount.status === 'idle') {
          await dispatch(
            fetchFinancialAccount({
              connAcc: account_id,
              finAcc: financial_account_id,
              token: token,
            }),
          );
        }

        // Fetch Connected Account
        if (connAccount.status === 'idle') {
          await dispatch(fetchConnectedAccount({ id: account_id, token }));
        }

        // Fetch persons attached to the account
        if (connAccountPersons.status === 'idle') {
          await dispatch(
            fetchConnectedAccountPersons({ id: account_id, token }),
          );
        }

        // Fetch reference to card
        if (account.data.details_filled && cardReferences.status === 'idle') {
          await dispatch(fetchCardReferences({ token: token, user: user.sub }));
        }

        // Fetch cards
        if (account.data.details_filled && cards.status === 'idle') {
          await dispatch(fetchCards({ connAcc: account_id, token }));
        }

        if (
          connAccount.status === 'succeeded' &&
          !account.data.details_filled
        ) {
          await updateAccStatus(token);
        }

        // Fetch notifications
        if (notifications.status === 'idle') {
          await dispatch(fetchNotification({ token }));
        }
      }
    };

    loadSupportInProduction({
      naum: user.name,
      email: user.email,
      user: user.sub,
      wallet: account.data.account_id,
    });

    if (accessToken) {
      loadData(accessToken);
    }
  });

  const updateAccStatus = async (token: string) => {
    let missingRequirements = false;

    const {
      currently_due,
      eventually_due,
      past_due,
      pending_verification,
      errors,
    } = connAccount.data.requirements;

    currently_due.forEach((item) => {
      if (item !== 'external_account') {
        missingRequirements = true;
      }
    });

    eventually_due.forEach((item) => {
      if (item !== 'external_account') {
        missingRequirements = true;
      }
    });

    past_due.forEach((item) => {
      if (item !== 'external_account') {
        missingRequirements = true;
      }
    });

    if (
      !missingRequirements &&
      pending_verification.length === 0 &&
      errors.length === 0
    ) {
      // Set details_filled on accounts to true
      await dispatch(
        updateDetails({
          accountId: account.data.account_id,
          token: token,
          userId: user.sub,
        }),
      );
    }
  };

  // Ensure user email have been verified
  if (!user.email_verified) {
    return <VerifyEmail />;
  }

  if (account.status === 'succeeded' && !account.data.details_filled) {
    return <WalletGenerator />;
  }

  return (
    <>
      <NavBar active={account.data.is_active} navigation={nav} />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/transactions' component={Transactions} />
        <Route exact path='/payments' component={Payments} />
        <Route exact path='/cards' component={Cards} />
        <Route exact path='/account/settings' component={AccountSettings} />
        <Route path='*' component={NotAValidLink} />
      </Switch>
      <Footer styles={{ width: '100%' }} links={false} social={false} />
      <BottomNav navigation={bottomNavs} />
    </>
  );
};

export default WithNavbar;
