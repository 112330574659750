import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ChevronRightIcon,
  CogIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/outline';
import { Modal } from 'Components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectAccessToken, selectAccount } from 'features';
import { sleep } from 'utils';
import ActivateCard, { SetPin } from '../ActivateCard';
import LockCard from '../LockCard';
import CancelCard from '../CancelCard';
import { Card } from '../cardTypes';
import { updateCard } from '../';

interface SettingsProps {
  description: string;
  cardDetails: Card;
}

enum selectedOptions {
  reset,
  lock,
  cancel,
  activate,
}

const settingOptions = [
  {
    name: 'Reset PIN',
    description: "Change your card's PIN number",
    identifier: selectedOptions.reset,
  },
  {
    name: 'Lock card',
    description: 'Temporarily lock card',
    identifier: selectedOptions.lock,
  },
  {
    name: 'Cancel card',
    description: 'Lost, damaged or stolen',
    identifier: selectedOptions.cancel,
  },
];

const CardSettings: React.FC<SettingsProps> = ({
  description,
  cardDetails,
}: SettingsProps) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const accessToken = useAppSelector(selectAccessToken);

  const [showModal, setShowModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('Card settings');
  const [option, setOption] = useState(-1);

  const showSettings = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const selectOption = (identifier: number, name: string) => {
    setOption(identifier);
    setModalHeading(name);
  };

  const closeModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowModal(false);
    setModalHeading('Card settings');
    setOption(-1);
  };

  const goBack = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setModalHeading('Card settings');
    setOption(-1);
  };

  const selectLockCard = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!accessToken) {
      console.error(
        'Failed to lock card because access token was not in store',
      );
      return;
    }
    if (cardDetails.status.toLocaleLowerCase() !== 'inactive') {
      setOption(selectedOptions.lock);
      setShowModal(true);
    } else {
      sleep(2000).then(async () => {
        await dispatch(
          updateCard({
            connectedAccount: account.data.account_id,
            id: cardDetails.id,
            params: {
              status: 'active',
            },
            token: accessToken,
          }),
        );
      });
    }
  };

  const switchHandler = () => {
    switch (option) {
      case selectedOptions.reset:
        return (
          <SetPin
            cardId={cardDetails.id}
            close={closeModal}
            description='Create a new 4-digit PIN code for your card.'
            descriptionHeading='Reset your card’s PIN code.'
            goBack={goBack}
            header='Reset PIN'
            pinFieldPlaceholder='New PIN'
            setHeader={setModalHeading}
            successHeader='Your PIN has been reset'
          />
        );
      case selectedOptions.lock:
        return (
          <LockCard
            cardId={cardDetails.id}
            close={closeModal}
            goBack={goBack}
            setHeader={setModalHeading}
          />
        );
      case selectedOptions.cancel:
        return (
          <CancelCard
            cardId={cardDetails.id}
            close={closeModal}
            goBack={goBack}
            header='Cancel card'
            setHeader={setModalHeading}
          />
        );
      case selectedOptions.activate:
        return (
          <ActivateCard
            last_4={cardDetails.last4}
            close={closeModal}
            cardId={cardDetails.id}
            setHeader={setModalHeading}
          />
        );
      default:
        return (
          <>
            {settingOptions.map((item, index) => {
              return (
                <SettingOption
                  key={index}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    selectOption(item.identifier, item.name);
                  }}
                >
                  <div>
                    <h5>{item.name}</h5>
                    <p>{item.description}</p>
                  </div>
                  <div>
                    <ChevronRightIcon />
                  </div>
                </SettingOption>
              );
            })}
          </>
        );
    }
  };
  // TODO: Display when the card has been delivered
  return (
    <SettingsDetails>
      <SettingsDescription
        statusColor={
          cardDetails.pin
            ? cardDetails.status === 'active'
              ? 'var(--color-green-5)'
              : cardDetails.status === 'inactive'
              ? 'var(--color-grey-5)'
              : 'var(--color-orange-1)'
            : 'var(--color-orange-1)'
        }
      >
        <H5>{description}</H5>
        {cardDetails.status === 'active' ? (
          <p>{cardDetails.pin ? 'Active' : 'On the way'}</p>
        ) : (
          <>
            {cardDetails.status === 'canceled' ? (
              <p>Canceled</p>
            ) : (
              <p>{cardDetails.pin ? 'Locked' : 'On the way'}</p>
            )}
          </>
        )}
      </SettingsDescription>
      {cardDetails.status !== 'canceled' && (
        <SettingsFunctions>
          {cardDetails.pin !== null && cardDetails.pin !== undefined ? (
            <>
              {cardDetails.status.toLocaleLowerCase() === 'inactive' ? (
                <a href='/' onClick={selectLockCard}>
                  <LockOpenIcon />
                </a>
              ) : (
                <a href='/' onClick={selectLockCard}>
                  <LockClosedIcon />
                </a>
              )}
              <a href='/' onClick={showSettings}>
                <CogIcon />
              </a>
            </>
          ) : (
            <>
              <a
                href='/'
                style={{
                  backgroundColor: 'var(--color-green-2)',
                  color: 'var(--color-green-8)',
                  fontSize: 'initial',
                  gridColumn: 'span 8 / span 8',
                  padding: '12px',
                  verticalAlign: 'bottom',
                }}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  setShowModal(true);
                  setOption(selectedOptions.activate);
                }}
              >
                Activate card
              </a>
            </>
          )}
        </SettingsFunctions>
      )}
      {showModal && (
        <Modal
          heading={modalHeading}
          setShowModal={setShowModal}
          showModal={showModal}
          closeHandler={closeModal}
        >
          {switchHandler()}
        </Modal>
      )}
    </SettingsDetails>
  );
};

const H5 = styled.h5`
  color: var(--color-black);
  font-size: var(--font-size-h5);
  margin: 0;
`;

const SettingsDetails = styled.div`
  margin: var(--spacing-sm) auto;
  max-width: 380px;
  width: 100%;
`;

interface settingsDescProps {
  readonly statusColor?: string;
}

const SettingsDescription = styled.div<settingsDescProps>`
  display: inline-grid;
  width: 40%;

  p {
    color: ${(props) =>
      props.statusColor ? props.statusColor : 'var(--color-gey-5)'};
    font-size: 14px;
  }
`;

const SettingsFunctions = styled.div`
  display: inline-block;
  margin: 0;
  text-align: right;
  width: 60%;

  a {
    background-color: var(--color-grey-2);
    border-radius: var(--border-radius-sm);
    color: var(--color-black);
    display: inline-block;
    margin-right: 2px;
    padding: 12px;
    svg {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }
`;

const SettingOption = styled.a`
  border-bottom: 1px solid var(--color-grey-3);
  display: block;
  font-size: var(--font-size-body);
  line-height: 24px;
  padding: 15px 0;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }

  div {
    display: inline-block;
    margin: 0;

    &:first-child {
      width: 95%;
    }

    &:last-child {
      color: var(--color-grey-10);
      vertical-align: super;
      width: 5%;
    }
  }

  h5 {
    font-weight: 400;
    margin: 0;
  }

  p {
    color: var(--color-grey-5);
    margin: 0 0 5px !important;
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    p {
      font-size: 14px !important;
    }
  }
`;

export default CardSettings;
