import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CircularProgress } from '@material-ui/core/';
import {
  Button,
  ErrorContainer,
  ErrorMessage,
  FormInput,
} from 'app-components';
import { ModalButtons } from 'Components';
import { sleep } from 'utils';

const schema = yup.object().shape({
  card_num_last_4: yup
    .string()
    .required('Four diigits are required')
    .max(4, 'Maximum four digits long')
    .min(4, 'Minimum four digits long')
    .matches(/^[+-]?\d*$/, 'Only numbers allowed'),
});

interface FormInputs {
  card_num_last_4: number;
}

interface Props {
  last_4_digits: string;
  close: (e: React.MouseEvent<HTMLElement>) => void;
  header?: string;
  setHeader: Dispatch<SetStateAction<string>>;
  setProcessed: Dispatch<SetStateAction<boolean>>;
}

const CardDigits: FC<Props> = ({
  last_4_digits,
  close,
  header,
  setHeader,
  setProcessed,
}: Props) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ resolver: yupResolver(schema) });

  const handleActivate = (data: FormInputs) => {
    // TODO: Set this to only have 4 tries
    setProcessing(true);
    setError('');
    sleep(1000).then(() => {
      if (data.card_num_last_4.toString() !== last_4_digits.toString()) {
        setProcessing(false);
        setError(
          'Sorry last 4 digits on file does not match with what you provided',
        );
        return;
      }
      setProcessing(false);
      setProcessed(true);
    });
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError('');
  };

  useEffect(() => {
    if (header) {
      setHeader(header);
    }
  });

  return (
    <>
      <h5>Enter the last 4 digits of your card</h5>
      <p>
        We’ll immediately activate your card after you confirm the last 4 digits
        of your card number.
      </p>
      <form onSubmit={handleSubmit(handleActivate)} method='post'>
        <FormInput
          error={errors.card_num_last_4}
          handleChange={handleChange}
          id='card_num_last_4'
          label='Last 4 digits'
          name='card_num_last_4'
          placeholder='Last 4 digits'
          register={register}
          type='password'
        />
        <ModalButtons>
          <Button type='reset' onClick={close}>
            Cancel
          </Button>
          <Button type='submit' disabled={processing}>
            {processing ? (
              <CircularProgress
                style={{
                  color: '#fff',
                  height: '20px',
                  width: '20px',
                  margin: '0 5px 0 0',
                }}
              />
            ) : (
              'Activate Card'
            )}
          </Button>
        </ModalButtons>
      </form>

      <ErrorContainer id='error-message' show={errors.card_num_last_4 || error}>
        {errors.card_num_last_4 && (
          <ErrorMessage
            style={{ color: 'var(--color-error-red)', fontSize: '16px' }}
          >
            {errors.card_num_last_4.message}
          </ErrorMessage>
        )}
        {error && !errors.card_num_last_4 && (
          <ErrorMessage
            style={{ color: 'var(--color-error-red)', fontSize: '16px' }}
          >
            {error}
          </ErrorMessage>
        )}
      </ErrorContainer>
    </>
  );
};

CardDigits.defaultProps = {
  header: 'Activate Card',
};

export default CardDigits;
