import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Button,
  ErrorContainer,
  ErrorMessage,
  FormInput,
} from 'app-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core/';
import { selectFinancialAccount } from 'features';
import { useAppSelector } from 'hooks';
import { ModalButtons } from 'Components';
import { sleep } from 'utils';

const schema = yup.object().shape({
  amount: yup
    .string()
    .required('The amount you would like to transfer is required')
    .matches(/^[+-]?\d*[.]?[+-]?\d*$/, 'Only numbers are allowed in amount'),
  accountHolder: yup.string().required('Please state the account holder'),
  accountNumber: yup
    .string()
    .required('Tell us the account to transfer to')
    .matches(/^[+-]?\d*$/, 'Only numbers are allowed in account numbers'),
  routingNumber: yup
    .string()
    .required('Please provide your bank routing number')
    .matches(/^[+-]?\d*$/, 'Only numbers are allowed for routing numbers'),
  accountNumberConfirmation: yup
    .string()
    .required('Please confirm your account number')
    .oneOf(
      [yup.ref('accountNumber'), null],
      'Account number confirmation does not match',
    ),
});

interface FormInputs {
  amount: number;
  accountHolder: string;
  accountNumber: string;
  routingNumber: string;
  accountNumberConfirmation: number;
}

interface Props {
  setAmount: Dispatch<SetStateAction<number>>;
  setAccountHolder: Dispatch<SetStateAction<string>>;
  setAccountNumber: Dispatch<SetStateAction<string>>;
  setRoutingNumber: Dispatch<SetStateAction<string>>;
  setHeader: Dispatch<SetStateAction<string>>;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setStage: Dispatch<SetStateAction<number>>;
}

const ACHTransferForm: React.FC<Props> = ({
  setAmount,
  setAccountHolder,
  setAccountNumber,
  setRoutingNumber,
  setHeader,
  setShowModal,
  setStage,
}: Props) => {
  const financialAccount = useAppSelector(selectFinancialAccount);
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ resolver: yupResolver(schema) });

  const handleTransfer = async (data: FormInputs) => {
    if (error) {
      return;
    }
    setProcessing(true);
    sleep(1000).then(() => {
      setAmount(data.amount);
      setAccountHolder(data.accountHolder);
      setAccountNumber(data.accountNumber);
      setRoutingNumber(data.routingNumber);
      setStage(2);
      setHeader('Confirm payment');
      setProcessing(false);
    });
  };

  const handleAmount = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    // Replace all values that aren't numbers
    let val = e.currentTarget.value.replace(/[^\d.-]/g, '');
    val =
      val.indexOf('.') >= 0
        ? val.substr(0, val.indexOf('.')) + val.substr(val.indexOf('.'), 3)
        : val;
    e.currentTarget.value = val;

    const value = Math.floor(parseFloat(val) * 100);
    const currentBalance = financialAccount.data.balance.current.usd;

    if (isNaN(parseFloat(val))) {
      setError(true);
      setErrorMsg('Only numbers allowed in amount');
    } else if (value > currentBalance) {
      setError(true);
      setErrorMsg('Your balance is too low for this transaction');
    } else if (value <= 0 || value > 50000) {
      setError(true);
      setErrorMsg('Amount must be between 1.00 and 500.00 inclusive');
    } else {
      setError(false);
      setErrorMsg('');
    }
  };

  const handleNumberChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.currentTarget.value = e.currentTarget.value.replace(/[^\d-]/g, '');
  };

  return (
    <>
      <p>Send funds to another US account.</p>
      <Form error={error} onSubmit={handleSubmit(handleTransfer)} method='post'>
        <FormInput
          error={errors.amount || error}
          handleChange={handleAmount}
          id='amount'
          label='Amount'
          name='amount'
          placeholder='Enter amount'
          register={register}
          showLabel={true}
          type='string'
        />
        <FormInput
          error={errors.accountHolder}
          id='accountHolder'
          label='Account Holder'
          name='accountHolder'
          placeholder='Account name'
          register={register}
          type='text'
        />
        <FormInput
          error={errors.routingNumber}
          handleChange={handleNumberChange}
          id='routingNumber'
          label='routingNumber'
          name='routingNumber'
          placeholder='Routing number'
          register={register}
          type='text'
        />
        <FormInput
          error={errors.accountNumber}
          handleChange={handleNumberChange}
          id='accountNumber'
          label='accountNumber'
          name='accountNumber'
          placeholder='Account number'
          register={register}
          type='text'
        />
        <FormInput
          error={errors.accountNumberConfirmation}
          handleChange={handleNumberChange}
          id='accountNumberConfirmation'
          label='accountNumberConfirmation'
          name='accountNumberConfirmation'
          placeholder='Confirm Account Number'
          register={register}
          type='text'
        />
        <ModalButtons>
          <Button
            type='reset'
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
          <Button type='submit' disabled={processing || error}>
            {processing ? (
              <CircularProgress
                style={{
                  color: '#fff',
                  height: '20px',
                  width: '20px',
                  margin: '0 5px 0 0',
                }}
              />
            ) : (
              'Confirm'
            )}
          </Button>
        </ModalButtons>
      </Form>
      <ErrorContainer
        id='error-message'
        show={
          error ||
          errors.amount ||
          errors.accountHolder ||
          errors.accountNumber ||
          errors.routingNumber ||
          errors.accountNumberConfirmation
        }
      >
        {error && (
          <ErrorMessage
            style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
          >
            {errorMsg}
          </ErrorMessage>
        )}
        {errors.amount && (
          <ErrorMessage
            style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
          >
            {errors.amount.message}
          </ErrorMessage>
        )}
        {errors.accountHolder && (
          <ErrorMessage
            style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
          >
            {errors.accountHolder.message}
          </ErrorMessage>
        )}
        {errors.accountNumber && (
          <ErrorMessage
            style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
          >
            {errors.accountNumber.message}
          </ErrorMessage>
        )}
        {errors.routingNumber && (
          <ErrorMessage
            style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
          >
            {errors.routingNumber.message}
          </ErrorMessage>
        )}
        {errors.accountNumberConfirmation && (
          <ErrorMessage
            style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
          >
            {errors.accountNumberConfirmation.message}
          </ErrorMessage>
        )}
      </ErrorContainer>
    </>
  );
};

interface FormProps {
  readonly error: boolean;
}

const Form = styled.form<FormProps>`
  div {
    &:first-child {
      margin: inherit inherit 20px;

      label {
        color: ${(props) =>
          props.error ? 'var(--color-red-err)' : 'var(--color-grey-6)'};
      }

      input {
        background-color: initial;
        border: none;
        font-size: 40px;
        margin-top: 0px;
        margin-left: 10px;
        padding: 0 var(--spacing-base) calc(var(--spacing-base) * 1.25) 0;
        color: ${(props) =>
          props.error ? 'var(--color-red-err)' : 'var(--color-grey-6)'};
        &::before {
          position: relative;
          left: -1.5em;
          content: '$';
          margin-left: -10px;
          font-size: 40px;
        }
      }
    }
  }
`;
export default ACHTransferForm;
