export const nav = [
  {
    id: 'tab-home',
    name: 'Home',
    path: '/',
  },
  {
    id: 'tab-transactions',
    name: 'Transactions',
    path: '/transactions',
  },
  {
    id: 'tab-payments',
    name: 'Payments',
    path: '/payments',
  },
  {
    id: 'tab-cards',
    name: 'Cards',
    path: '/cards',
  },
];

export const bottomNavs = [
  {
    icon: 'collection-icon.svg',
    id: 'tab-mobile-transactions',
    name: 'Transactions',
    path: '/transactions',
  },
  {
    icon: 'calendar-icon.svg',
    id: 'tab-mobile-payments',
    name: 'Payments',
    path: '/payments',
  },
  {
    icon: 'home-icon.svg',
    id: 'tab-mobile-home',
    name: 'Home',
    path: '/',
  },
  {
    icon: 'home-icon.svg',
    id: 'tab-mobile-cards',
    name: 'Cards',
    path: '/cards',
  },
];

export const data = [
  {
    name: 'PayPal',
    description: 'June 02, 2021',
    amount: '1,679.42',
    success: true,
    debit: false,
  },
  {
    name: 'UnitedMasters',
    description: 'June 01, 2021',
    amount: '4,849.12',
    success: true,
    debit: true,
  },
  {
    name: 'W3 Schools',
    description: 'June 01, 2021',
    amount: '49.00',
    success: true,
    debit: false,
  },
  {
    name: 'Spotify',
    description: 'Failed - Insufficient funds',
    amount: '18.59',
    success: false,
    debit: false,
  },
];

export const lastMonthData = [
  {
    name: 'GSuite',
    description: 'May 05, 2021',
    amount: '15.29',
    success: true,
    debit: false,
  },
  {
    name: 'Microsoft',
    description: 'Failed - Limit Exceeded',
    amount: '687.21',
    success: false,
    debit: false,
  },
];

export const shopping = [
  {
    id: 'trxn_1J8pCHQOGGDsvQLW4kJgHH13',
    object: 'transaction',
    amount: -18395,
    balance_impact: {
      cash: {
        available: -18395,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: -18395,
      type: 'cash',
    },
    created: 1625243191,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Purchase | BK FIT STUDIOS | Country Clubs',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'recd_1J8pCFQOGGDsvQLW5GvRKkvy',
    flow_type: 'received_debit',
    livemode: true,
    status: 'posted',
    status_transitions: {
      posted_at: 1625243191,
      voided_at: null,
    },
    treasury: {
      current_balance_amount: 20766,
      regulatory_receipt_url:
        'https://payments.stripe.com/treasury/receipt/trxn_live_YWNjdF8xSjNwZHBRT0dHRHN2UUxXLGJ0eHJfSm1OeUExajlSMERiellaVWtENXRseFNINjBXSjlpTg0000NhJih4XE',
    },
  },
  {
    id: 'trxn_1J8pAcQOGGDsvQLWitzxASjH',
    object: 'transaction',
    amount: -310,
    balance_impact: {
      cash: {
        available: -310,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: -310,
      type: 'cash',
    },
    created: 1625243088,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Purchase | DUNKIN #331707 | Fast Food Restaurants',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'recd_1J8pAaQOGGDsvQLWmGkFleof',
    flow_type: 'received_debit',
    livemode: true,
    status: 'posted',
    status_transitions: {
      posted_at: 1625243088,
      voided_at: null,
    },
    treasury: {
      current_balance_amount: 39161,
      regulatory_receipt_url:
        'https://payments.stripe.com/treasury/receipt/trxn_live_YWNjdF8xSjNwZHBRT0dHRHN2UUxXLGJ0eHJfSm1Od2Y4ekpVWFJrblBMYmZhN2ZvOEVHUU9iSnlhMg0000CeLPNfuC',
    },
  },
  {
    id: 'trxn_1J8osiQOGGDsvQLW5xZJo9xd',
    object: 'transaction',
    amount: -7950,
    balance_impact: {
      cash: {
        available: -7950,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: -7950,
      type: 'cash',
    },
    created: 1625241978,
    currency: 'usd',
    description:
      "Daniel Dewar (9054) | Purchase | TST* L'EXPRESS | Eating Places, Restaurants",
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'recd_1J8osgQOGGDsvQLWHmdOveFa',
    flow_type: 'received_debit',
    livemode: true,
    status: 'posted',
    status_transitions: {
      posted_at: 1625241978,
      voided_at: null,
    },
    treasury: {
      current_balance_amount: 39471,
      regulatory_receipt_url:
        'https://payments.stripe.com/treasury/receipt/trxn_live_YWNjdF8xSjNwZHBRT0dHRHN2UUxXLGJ0eHJfSm1OZUNwSnJtVEhBcmJxajBJMlBIenQ3M3U5WTlQNQ0000TZqOnVJj',
    },
  },
  {
    id: 'trxn_1J8oXFQOGGDsvQLWOjnCDh73',
    object: 'transaction',
    amount: -652,
    balance_impact: {
      cash: {
        available: -652,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: -652,
      type: 'cash',
    },
    created: 1625240647,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Purchase | TST* GREGORYS COFFEE - GC | Eating Places, Restaurants',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'recd_1J8oXDQOGGDsvQLWMmNsBcPU',
    flow_type: 'received_debit',
    livemode: true,
    status: 'posted',
    status_transitions: {
      posted_at: 1625240647,
      voided_at: null,
    },
    treasury: {
      current_balance_amount: 47421,
      regulatory_receipt_url:
        'https://payments.stripe.com/treasury/receipt/trxn_live_YWNjdF8xSjNwZHBRT0dHRHN2UUxXLGJ0eHJfSm1OSERkR0tGdlRqaWpNc0hUdzRRRFdOZFlVZFVYaA0000sQ0DAn8D',
    },
  },
  {
    id: 'trxn_1J8kp5QOGGDsvQLWJjAu5Z7Z',
    object: 'transaction',
    amount: -425,
    balance_impact: {
      cash: {
        available: -425,
        inbound_pending: 0,
        outbound_pending: 425,
      },
      current: 0,
      type: 'cash',
    },
    created: 1625226378,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Authorization | SQ *HUNGRY GHOST BEDFO | Eating Places, Restaurants',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'rech_1J8kp4QOGGDsvQLWuCt8puB4',
    flow_type: 'received_hold',
    livemode: true,
    status: 'open',
    status_transitions: {
      posted_at: null,
      voided_at: null,
    },
    treasury: {
      current_balance_amount: null,
      regulatory_receipt_url: null,
    },
  },
  {
    id: 'trxn_1J8XGrQOGGDsvQLWW2ityIrG',
    object: 'transaction',
    amount: 0,
    balance_impact: {
      cash: {
        available: 0,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: 0,
      type: 'cash',
    },
    created: 1625174285,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Authorization | DUNKIN #331707 | Fast Food Restaurants',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'rech_1J8XGqQOGGDsvQLWHktwp9oX',
    flow_type: 'received_hold',
    livemode: true,
    status: 'void',
    status_transitions: {
      posted_at: null,
      voided_at: 1625243088,
    },
    treasury: {
      current_balance_amount: null,
      regulatory_receipt_url: null,
    },
  },
  {
    id: 'trxn_1J8Vh3QOGGDsvQLWFxjAl67q',
    object: 'transaction',
    amount: 0,
    balance_impact: {
      cash: {
        available: 0,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: 0,
      type: 'cash',
    },
    created: 1625168220,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Authorization | TST*GREGORYS COFFEE - GC0 | Eating Places, Restaurants',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'rech_1J8Vh2QOGGDsvQLWvIE82ubq',
    flow_type: 'received_hold',
    livemode: true,
    status: 'void',
    status_transitions: {
      posted_at: null,
      voided_at: 1625240647,
    },
    treasury: {
      current_balance_amount: null,
      regulatory_receipt_url: null,
    },
  },
  {
    id: 'trxn_1J8UMJQOGGDsvQLW6nbby9Dn',
    object: 'transaction',
    amount: 0,
    balance_impact: {
      cash: {
        available: 0,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: 0,
      type: 'cash',
    },
    created: 1625163091,
    currency: 'usd',
    description:
      "Daniel Dewar (9054) | Authorization | TST* L'EXPRESS | Eating Places, Restaurants",
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'rech_1J8UMIQOGGDsvQLWh3UdT0kG',
    flow_type: 'received_hold',
    livemode: true,
    status: 'void',
    status_transitions: {
      posted_at: null,
      voided_at: 1625241978,
    },
    treasury: {
      current_balance_amount: null,
      regulatory_receipt_url: null,
    },
  },
  {
    id: 'trxn_1J8RJDQOGGDsvQLWtemR6gh4',
    object: 'transaction',
    amount: -1927,
    balance_impact: {
      cash: {
        available: -1927,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: -1927,
      type: 'cash',
    },
    created: 1625151365,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Purchase | SQ *CUP OF BROOKLYN | Fast Food Restaurants',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'recd_1J8RJBQOGGDsvQLWoOrZe66z',
    flow_type: 'received_debit',
    livemode: true,
    status: 'posted',
    status_transitions: {
      posted_at: 1625151365,
      voided_at: null,
    },
    treasury: {
      current_balance_amount: 48073,
      regulatory_receipt_url:
        'https://payments.stripe.com/treasury/receipt/trxn_live_YWNjdF8xSjNwZHBRT0dHRHN2UUxXLGJ0eHJfSmx6SFZCZlF1NWlrN042QWxKU25JclZjeHBTQzlzaA0000qqIRBZtn',
    },
  },
  {
    id: 'trxn_1J86rlQOGGDsvQLW3AU8Fnb8',
    object: 'transaction',
    amount: 0,
    balance_impact: {
      cash: {
        available: 0,
        inbound_pending: 0,
        outbound_pending: 0,
      },
      current: 0,
      type: 'cash',
    },
    created: 1625072785,
    currency: 'usd',
    description:
      'Daniel Dewar (9054) | Authorization | BK FIT STUDIOS | Country Clubs',
    financial_account: 'fa_1J3pdsQOGGDsvQLW1LZdiDsf',
    financial_account_type: 'treasury',
    flow: 'rech_1J86rkQOGGDsvQLWmrNuSTlH',
    flow_type: 'received_hold',
    livemode: true,
    status: 'void',
    status_transitions: {
      posted_at: null,
      voided_at: 1625243191,
    },
    treasury: {
      current_balance_amount: null,
      regulatory_receipt_url: null,
    },
  },
];
