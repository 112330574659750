export interface Address {
  city: string;
  country: string;
  line1: string;
  line2: string | null;
  postal_code: string;
  state: string;
}

export interface Args {
  token: string;
  user: string;
}

export interface Billing {
  address: Address;
}

export interface Requirements {
  disabled_reasons: string | null;
  past_due: any[];
}

export interface SpendingLimits {
  amount: number;
  categories: any[];
  interval: 'daily' | 'weekly' | 'monthly' | 'yearly';
}

export interface SpendingControls {
  allowed_categories: any[];
  blocked_categories: any[];
  spending_limits: SpendingLimits[];
  spending_limits_currency: 'usd' | string;
}

export interface Metadata {
  any: any;
}

export interface CardHolder {
  billing: Billing;
  company: string | null;
  created: number;
  email: string;
  id: string;
  individual: string | null;
  livemode: boolean;
  metadata: Metadata;
  name: string;
  phone_number: string;
  requirements: Requirements;
  spending_controls: SpendingControls;
  status: 'active' | 'inactive';
  type: string;
}

export interface Pin {
  status: string;
}

export interface Shipping {
  address: Address;
  carrier: 'ups' | 'usps' | 'dhl' | 'fedex';
  eta: number;
  name: string;
  phone: string | null;
  status: string;
  tracking_number: null | string | number;
  tracking_url: null | string;
  type: 'company' | 'individual';
}

interface CreateCardReferenceData {
  name: string;
  email: string;
  phone_number: string;
  address: Address;
}

export interface CardReferenceArgs {
  data: CreateCardReferenceData;
  token: string;
  userId: string;
}

export interface Card {
  brand: 'VISA' | string;
  cancellation_reason: 'lost' | 'stolen';
  cardholder: CardHolder;
  created: number;
  currency: 'us' | string;
  exp_month: number;
  exp_year: number;
  financial_account: string;
  id: string;
  last4: string;
  livemode: boolean;
  metadata: Metadata;
  object: string;
  pin: Pin | null;
  replaced_by: string | null;
  replacement_for: string | null;
  replacement_reason: string | null;
  shipping: Shipping;
  spending_controls: SpendingControls;
  status: 'active' | 'canceled' | 'inactive';
  type: 'physical' | 'virtual';
}

export interface InitialState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  cards: Card[];
}

export interface CardReference {
  card_holder_id: string;
  card_id: string;
  created_at: string;
  created_by: string;
  id: number;
  is_active: boolean;
  payee_id: string;
}

export interface CardReferenceInitialState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  cards: CardReference;
}

export const months = {
  1: '01',
  2: '02',
  3: '03',
  4: '04',
  5: '05',
  6: '06',
  7: '07',
  8: '08',
  9: '09',
  10: '10',
  11: '11',
  12: '12',
};
