import React, { useState } from 'react';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { PrimaryCard } from 'features';
import { Modal, Section, SendMoneyButton } from 'Components';
import ACHTransfers from './ACHTransfers';

export const SendMoney: React.FC = () => {
  const [header, setHeader] = useState('Send a payment');
  const [showTransfer, setShowTransfer] = useState(false);

  const closeTransfers = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowTransfer(false);
  };

  const achTransfer = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setHeader('Send a payment');
    setShowTransfer(true);
  };

  return (
    <Section
      className='col-span-4 sm-col-span-full lg-col-span-6'
      style={{ height: 'fit-content' }}
    >
      <PrimaryCard />
      <SendMoneyButton id='dashboard-send-money' onClick={achTransfer}>
        <ArrowRightIcon className='icons' />
        Send Money
      </SendMoneyButton>
      <Modal
        heading={header}
        closeHandler={closeTransfers}
        showModal={showTransfer}
        setShowModal={setShowTransfer}
      >
        <ACHTransfers closeModal={setShowTransfer} setHeader={setHeader} />
      </Modal>
    </Section>
  );
};

export default SendMoney;
