import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MainContainer, TextLabel } from 'Components';
import { getStripeEditLink } from 'utils';
import {
  Notifications,
  selectAccount,
  selectConnectedAccount,
  selectConnectedAccountPersons,
} from 'features';
import { useAppSelector } from 'hooks';

const AccountSettings = () => {
  const history = useHistory();
  const account = useAppSelector(selectAccount);
  const connectedAccount = useAppSelector(selectConnectedAccount);
  const connectedAccountPersons = useAppSelector(selectConnectedAccountPersons);

  if (account.status === 'succeeded' && !account.data.is_active) {
    history.push('/');
  }

  const handleEdit = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { account_id } = account.data;
    getStripeEditLink(account_id).then((res) => {
      window.location.href = res.data.url;
    });
  };

  const { address, phone, name } = connectedAccount.data.company;
  const {
    email,
    first_name,
    last_name,
    relationship,
  } = connectedAccountPersons.persons.data[0];
  const personalPhone = connectedAccountPersons.persons.data[0].phone;
  const personalAddress = connectedAccountPersons.persons.data[0].address;
  const { title } = relationship;

  return (
    <MainContainer>
      <h1 className='col-span-full'>Account Settings</h1>
      <div className='col-span-6 md-col-span-full'>
        <SectionHeader>
          <h3>Personal details</h3>
          <TextLink href='#' onClick={handleEdit}>
            Edit
          </TextLink>
        </SectionHeader>
        <TextLabel label='Name' text={`${first_name} ${last_name}`} />
        <TextLabel label='Email' text={email} />
        <TextLabel label='Title' text={title} />
        <TextLabel
          label='Home address'
          text={`${address.line1}
           ${personalAddress.city}
           ${personalAddress.state}
           ${personalAddress.postal_code}
           ${personalAddress.country}`}
        />
        <TextLabel label='Phone number' text={personalPhone} />
      </div>
      <div className='col-span-6 md-col-span-full'>
        <SectionHeader>
          <h3>Business details</h3>
          <TextLink href='#' onClick={handleEdit}>
            Edit
          </TextLink>
        </SectionHeader>
        <TextLabel label='Legal business name' text={name} />
        <TextLabel
          label='Registered business address'
          text={`${address.line1}
           ${address.city}
           ${address.state}
           ${address.postal_code}
           ${address.country}`}
        />
        <TextLabel label='Business phone number' text={phone.toString()} />
      </div>
      <hr />
      <Notifications />
    </MainContainer>
  );
};

const SectionHeader = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;

  h3 {
    width: 80%;
    display: inline-block;
  }
`;

const TextLink = styled.a`
  color: var(--color-green-5);
`;

export default AccountSettings;
