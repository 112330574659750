import React from 'react';
import styled from 'styled-components';
import { LoadingIndicator, Logo } from 'app-components';

interface Props {
  fullHeight?: boolean;
}

const Loading: React.FC<Props> = ({ fullHeight }: Props) => {
  return (
    <Wrapper fullHeight={fullHeight}>
      <LoadingIndicator />
      <LoadingLogo src='https://storage.googleapis.com/pc-auth-assets/public/images/paperchain.svg' />
    </Wrapper>
  );
};

interface WrapperProps {
  readonly fullHeight?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingLogo = styled(Logo)`
  height: var(--font-size-h1);
  width: auto;
  opacity: 1;
  animation: fadeinout 2s linear infinite;

  @keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

Loading.defaultProps = {
  fullHeight: true,
};

export default Loading;
