import React, { useState, Dispatch, SetStateAction } from 'react';
import ACHTransferForm from './form';
import Transfer from './transfer';

interface Props {
  closeModal: Dispatch<SetStateAction<boolean>>;
  setHeader: Dispatch<SetStateAction<string>>;
}

const ACHTransfers: React.FC<Props> = ({ closeModal, setHeader }: Props) => {
  const [amount, setAmount] = useState(0);
  const [accountHolder, setAccountHolder] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [stage, setStage] = useState(1);

  return (
    <>
      {stage === 1 ? (
        <ACHTransferForm
          setAmount={setAmount}
          setAccountHolder={setAccountHolder}
          setAccountNumber={setAccountNumber}
          setRoutingNumber={setRoutingNumber}
          setHeader={setHeader}
          setShowModal={closeModal}
          setStage={setStage}
        />
      ) : (
        <>
          <Transfer
            amount={amount.toString()}
            accountHolder={accountHolder}
            accountNumber={accountNumber}
            close={closeModal}
            routingNumber={routingNumber}
            setHeader={setHeader}
            setStage={setStage}
          />
        </>
      )}
    </>
  );
};

export default ACHTransfers;
