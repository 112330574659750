import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  EmptyViewPlaceholder,
  MainContainer,
  TransactionList,
  BulletListLoader,
} from 'Components';
import { selectTransactions } from 'features';
import { useAppSelector } from 'hooks';
import { selectAccount } from 'features';
import {
  RequestStatus,
  Transactions as TransactionType,
} from '../../features/transactions/transactionTypes';

interface Props {
  transactions: TransactionType[];
  status: RequestStatus;
}

export const Transactions: React.FC<Props> = ({ status, transactions }) => {
  return (
    <MainContainer
      style={{ display: 'flex', flexDirection: 'column', minHeight: '70vh' }}
    >
      {(status === 'loading' || status === 'idle') && (
        <BulletListLoader height={500} />
      )}
      {status === 'succeeded' && transactions.length === 0 && (
        <EmptyViewPlaceholder
          heading='Transactions'
          description='All your transactions will be listed here'
        />
      )}

      {status === 'succeeded' && transactions.length > 0 && (
        <TransactionList transactions={transactions} />
      )}
    </MainContainer>
  );
};

const ConnectedTransactions: React.FC = () => {
  const { transactions, status: transactionStatus } = useAppSelector(
    selectTransactions,
  );
  const history = useHistory();
  const account = useAppSelector(selectAccount);

  if (account.status === 'succeeded' && !account.data.is_active) {
    history.push('/');
  }

  return (
    <Transactions transactions={transactions} status={transactionStatus} />
  );
};

export default ConnectedTransactions;
