import auth0 from 'auth0-js';
import { auth0Audience, auth0ClientId, auth0Domain } from 'settings';

const responseType = 'code';
export const WebAuth = new auth0.WebAuth({
  audience: auth0Audience,
  clientID: auth0ClientId,
  domain: auth0Domain,
  responseType,
});
