import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Auth0Error } from 'auth0-js';
import useWindowSize from 'react-use/lib/useWindowSize';
import { CircularProgress } from '@material-ui/core/';
import { SuccessPage } from 'TemplateParts';
import Confetti from 'react-confetti';
import {
  Button,
  ErrorContainer,
  ErrorMessage,
  FormInput,
  Logo,
} from 'app-components';
import { Footer } from 'app-components';
import { WebAuth } from 'auth';
import { sleep } from 'utils';
import { useSearchParam } from 'react-use';
import axios from 'axios';
import {
  UserResponse,
  invitationStatus as prefineryInvitationStatus,
} from './prefineryTypes';
import { Link } from 'react-router-dom';

const title = `Get paid by Paperchain`;

const schema = yup.object().shape({
  firstname: yup.string().required('Your firstname is required'),
  lastname: yup.string().required('Your lastname is required'),
  email: yup.string().email().required('A valid email is required'),
  password: yup
    .string()
    .required('Empty passwords are not allowed')
    .min(7, 'Password must be a minimum on 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!|[\-_)(:;'"\]/{<>.?+=}`~@#$%^&*,\\])(?=.{8,})/,
      'Passwords must contain One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm password')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  spotifyId: yup
    .string()
    .required(
      'For you to be paid by us, we need your Spotify ID in order to know your daily earnings',
    )
    .min(40, 'Spotify URL is too short')
    .matches(
      /^(https:\/\/open\.spotify\.com\/artist\/([A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]+))/,
      'Your valid spotify URL is required',
    ),
  role: yup.string().required('Please select a role'),
});

type roleType =
  | 'ARTIST'
  | 'AGENT'
  | 'COMPOSER'
  | 'LABEL'
  | 'MANAGER'
  | 'MUSICIAN'
  | 'PRODUCER'
  | 'PUBLISHER'
  | 'SONGWRITER'
  | 'OTHER';

interface FormInputs {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  confirmPassword: string;
  spotifyId: string;
  role: roleType;
}

interface IRoleOption {
  text: string;
  value: roleType;
}

const roleSelectOptions = [
  { text: 'Artist', value: 'ARTIST' },
  { text: 'Agent', value: 'AGENT' },
  { text: 'Composer', value: 'COMPOSER' },
  { text: 'Manager', value: 'MANAGER' },
  { text: 'Label', value: 'LABEL' },
  { text: 'Musician', value: 'MUSICIAN' },
  { text: 'Producer', value: 'PRODUCER' },
  { text: 'Songwriter', value: 'SONGWRITER' },
  { text: 'Other', value: 'OTHER' },
] as IRoleOption[];

const SignUp: React.FC = () => {
  const [signUpErr, setSignUpErr] = useState(false);
  const [signUpMessage, setSignUpMessage] = useState('');
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { width, height } = useWindowSize();
  const email = useSearchParam('email');
  const [invitationStatus, setInvitationStatus] = useState<
    prefineryInvitationStatus | 'not-applied' | 'request-failed' | undefined
  >();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  React.useEffect(() => {
    axios
      .get<UserResponse>(
        `https://us-central1-paperchain-143810.cloudfunctions.net/get-waitlist-user`,
        { params: { email } },
      )
      .then((res) => {
        if (res.status !== 200) {
          if (res.status === 400) {
            setInvitationStatus('not-applied');
            return;
          }
          console.warn('failed to fetch user from prefinery');
          setInvitationStatus('request-failed');
          return;
        }
        if (!res.data) {
          setInvitationStatus('not-applied');
          return;
        }

        setInvitationStatus(res.data.status);
        if (res.data.status !== 'invited') {
          return;
        }

        const { profile, email, responses } = res.data;
        setValue('email', email);
        setValue('firstname', profile.first_name);
        setValue('lastname', profile.last_name);
        const isCreatorUrlQuestionAnswered =
          responses?.length > 0 && responses[0].question_id === 103539;
        if (isCreatorUrlQuestionAnswered) {
          setValue('spotifyId', responses[0].answer);
        }
      });
  }, [email, setValue]);

  const handleSignup = (data: FormInputs) => {
    setLoading(true);
    sleep(1000).then(() => {
      let databaseConnection = 'Username-Password-Authentication';
      const { firstname, email, lastname, password, spotifyId, role } = data;
      WebAuth.signup(
        {
          email: email,
          password: password,
          username: `${firstname} ${lastname}`,
          connection: databaseConnection,
          userMetadata: {
            firstname: firstname,
            lastname: lastname,
            name: `${firstname} ${lastname}`,
            spotifyId: spotifyId,
            toc: 'true',
            role,
          },
        },
        (err: any | Auth0Error) => {
          if (err) {
            setSignUpErr(true);
            setSignUpMessage(err.description);
          } else {
            setSignUpErr(false);
            setSignUpSuccess(true);
          }
        },
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    document.title = title;
  }, []);

  const isInvited = invitationStatus && invitationStatus === 'invited';

  return (
    <>
      {signUpSuccess ? (
        <>
          <Confetti width={width} height={height} />
          <SuccessPage />
        </>
      ) : (
        <>
          <div
            className='login-container login-box'
            style={{ minHeight: '85vh' }}
          >
            <div
              className='grid width-full margin-base'
              style={{
                maxWidth: '612px',
                textAlign: 'center',
                margin: '25px auto 0',
              }}
            >
              <div className='col-span-full text-center'>
                <Logo
                  style={{ display: 'inline', height: '60px', width: '260px' }}
                  alt="Paperchain's logo"
                  src='https://storage.googleapis.com/pc-auth-assets/public/images/paperchain.svg'
                />
              </div>
              <ContentContainer className='content-container col-span-full'>
                {!isInvited ? (
                  <>
                    {email && !invitationStatus ? (
                      <CircularProgress
                        style={{
                          color: 'var(--color-grey-6)',
                          height: '20px',
                          width: '20px',
                          margin: '0 5px 0 0',
                        }}
                      />
                    ) : invitationStatus === 'not-applied' ? (
                      <ErrorMessage>
                        We are currently in closed beta.{' '}
                        <a
                          href='https://paperchain.io#waitlist'
                          style={{ cursor: 'pointer' }}
                        >
                          Sign up to our waitlist
                        </a>{' '}
                        and we'll send you an invite once we're ready to accept
                        new artists.
                      </ErrorMessage>
                    ) : invitationStatus === 'active' ? (
                      <div>
                        You are already signed up.
                        <p style={{ marginTop: '10px' }}>
                          <Link to='/login'>Log in</Link>
                        </p>
                      </div>
                    ) : invitationStatus === 'request-failed' ? (
                      <ErrorMessage>
                        Please refresh the page and try again. If the error
                        persists, chat with us at{' '}
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://paperchain.help'
                        >
                          paperchain.help
                        </a>{' '}
                        or email{' '}
                        <a href='mailto:support@paperchain.io'>
                          support@paperchain.io
                        </a>
                      </ErrorMessage>
                    ) : (
                      <ErrorMessage>
                        You have not been invited yet. If you have received an
                        invitation email and think this is a mistake, chat with
                        us at{' '}
                        <a
                          target='_blank'
                          rel='noreferrer'
                          href='https://paperchain.help'
                        >
                          paperchain.help
                        </a>{' '}
                        or email{' '}
                        <a href='mailto:support@paperchain.io'>
                          support@paperchain.io
                        </a>
                      </ErrorMessage>
                    )}
                  </>
                ) : (
                  <form
                    onSubmit={handleSubmit(handleSignup)}
                    method='post'
                    className='space-y-lg'
                  >
                    <Inline>
                      <FormInput
                        error={errors.firstname}
                        id='fnname'
                        label='First Name'
                        name='firstname'
                        placeholder='First Name'
                        register={register}
                        type='text'
                      />
                    </Inline>
                    <InlineSecond>
                      <FormInput
                        error={errors.lastname}
                        id='lname'
                        label='Last Name'
                        name='lastname'
                        placeholder='Last Name'
                        register={register}
                        type='text'
                      />
                    </InlineSecond>
                    <FormInput
                      error={errors.email}
                      id='email'
                      label='Email'
                      name='email'
                      placeholder='Email'
                      register={register}
                      type='email'
                      readOnly={true}
                    />
                    <FormInput
                      error={errors.password}
                      id='password'
                      label='Password'
                      name='password'
                      placeholder='Choose a password (min. 8 chars)'
                      register={register}
                      type='password'
                    />
                    <FormInput
                      error={errors.confirmPassword}
                      id='confirmPassword'
                      label='Confirm Password'
                      name='confirmPassword'
                      placeholder='Confirm password'
                      register={register}
                      type='password'
                    />
                    <FormInput
                      error={errors.spotifyId}
                      id='spotifyId'
                      label='Spotify ID'
                      name='spotifyId'
                      placeholder='Spotify URL'
                      register={register}
                      type='text'
                    />
                    <FormInput
                      label='Role'
                      name='role'
                      type='select'
                      register={register}
                      options={roleSelectOptions}
                    />

                    <div style={{ textAlign: 'center', alignItems: 'center' }}>
                      <CreateButton type='submit' id='btn-login'>
                        {loading ? (
                          <CircularProgress
                            style={{
                              color: '#fff',
                              height: '20px',
                              width: '20px',
                              margin: '0 5px 0 0',
                            }}
                          />
                        ) : (
                          <>Create Account</>
                        )}
                      </CreateButton>
                    </div>
                    <p id='toc' style={{ textAlign: 'left' }}>
                      By clicking "Create Account", you agree to Paperchain's{' '}
                      <a
                        href='https://paperchain.io/terms-of-use/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Terms of Use
                      </a>{' '}
                      and{' '}
                      <a
                        href='https://paperchain.io/privacy-policy'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                    <ErrorContainer
                      show={
                        errors.firstname ||
                        errors.lastname ||
                        errors.confirmPassword ||
                        errors.password ||
                        errors.confirmPassword ||
                        errors.spotifyId ||
                        signUpErr
                      }
                      id='error-message'
                    >
                      {errors.firstname && (
                        <ErrorMessage>{errors.firstname.message}</ErrorMessage>
                      )}
                      {errors.lastname && (
                        <ErrorMessage>{errors.lastname.message}</ErrorMessage>
                      )}
                      {errors.email && (
                        <ErrorMessage>{errors.email.message}</ErrorMessage>
                      )}
                      {errors.password && (
                        <ErrorMessage>{errors.password.message}</ErrorMessage>
                      )}
                      {errors.confirmPassword && (
                        <ErrorMessage>
                          {errors.confirmPassword.message}
                        </ErrorMessage>
                      )}
                      {errors.spotifyId && (
                        <ErrorMessage>{errors.spotifyId.message}</ErrorMessage>
                      )}
                      {signUpErr && (
                        <ErrorMessage>{signUpMessage}</ErrorMessage>
                      )}
                    </ErrorContainer>
                    <div className='captcha-container form-group'></div>
                  </form>
                )}
              </ContentContainer>
            </div>
          </div>
          <Footer
            links={false}
            social={false}
            styles={{ height: '15vh', margin: '10px' }}
          />
        </>
      )}{' '}
    </>
  );
};

const CreateButton = styled(Button)`
  box-shadow: 0 3px 15px #3ddc84, 0 10px 30px rgb(37 41 46 / 20%);
  @media screen and (min-width: 600px) {
    max-width: 280px;
  }
`;

const Inline = styled.div`
  @media screen and (min-width: 600px) {
    width: calc((100% - var(--spacing-base)) / 2);
    display: inline-block;
  }
`;

const InlineSecond = styled(Inline)`
  @media screen and (min-width: 600px) {
    margin-left: var(--spacing-base);
  }
`;

const ContentContainer = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SignUp;
