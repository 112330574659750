import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Wrapper = styled.div`
  max-width: var(--breakpoint-lg);
  margin: -110px auto 0;
  padding: var(--spacing-xl);
  @media screen and (max-width: 992px) {
    margin: -60px auto auto;
    padding: var(--spacing-base);
  }
  @media screen and (max-width: 800px) {
    margin: -90px auto auto;
    padding: var(--spacing-base);
  }
`;

const Container = styled.div`
  background-color: var(--color-white);
  border-radius: var(--border-radius-base);
  box-shadow: var(--drop-shadow-base);
  padding: var(--spacing-xl);
  @media screen and (max-width: 525px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Section = styled.div`
  background-color: var(--color-white);
  border-radius: var(--border-radius-base);
  box-shadow: 0 24px 100px -20px rgba(0, 0, 0, 0.15);
  padding: var(--spacing-xl);
  @media screen and (max-width: 800px) {
    padding: var(--spacing-base);
  }

  @media screen and (max-width: 380px) {
    h1 {
      font-size: 26px;
    }
  }
  @media screen and (max-width: 320px) {
    h1 {
      font-size: 24px;
    }
  }
`;

export const MainContainer: React.FC<Props> = ({
  children,
  ...style
}: Props) => {
  return (
    <Wrapper>
      <Container className='grid' {...style}>
        {children}
      </Container>
    </Wrapper>
  );
};
