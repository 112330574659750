import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';
import { stripeApi } from 'settings';
import { RootState } from 'store';
import { stripeOpts } from 'utils';
import { InitialState } from './types';

interface AchTransferArgs {
  amount: number;
  currency: 'usd';
  description: string;
  financial_account: string;
  network: 'ach' | 'stripe';
  destination_data: {
    type: 'aba';
    aba: {
      account_holder_name: string;
      account_number: string;
      routing_number: string;
    };
  };
}

interface ACHInfo {
  transferData: AchTransferArgs;
  accountId: string;
}

const initialState: InitialState = {
  status: 'idle',
  error: null,
  data: [],
};

export const createACHTransfer = createAsyncThunk(
  'transfer/createACHTransfer',
  async (args: ACHInfo) => {
    stripeOpts.headers['Stripe-Account'] = args.accountId;
    const response = await axios.post(
      `${stripeApi}/outbound_payments`,
      qs.stringify(args.transferData),
      stripeOpts,
    );
    return response.data.data;
  },
);

const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    createACHTransfer: (state, action) => {
      state.data = action.payload.data;
    },
  },
  extraReducers: {
    // @ts-ignore
    [createACHTransfer.pending]: (state) => {
      state.status = 'loading';
    },
    // @ts-ignore
    [createACHTransfer.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    // @ts-ignore
    [createACHTransfer.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.data = [];
    },
  },
});

export const selectTransfers = (state: RootState) => state.transfer;

export default transferSlice.reducer;
