import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  createRef,
} from 'react';
import styled from 'styled-components';
import { animated, useSpring } from '@react-spring/web';
import { XIcon } from '@heroicons/react/outline';
import { Logo } from 'app-components';

interface Props {
  children: ReactNode;
  heading?: string;
  closeHandler: (e: React.MouseEvent<HTMLElement>) => void;
  logo?: boolean;
  logoSrc?: string;
  showModal: boolean;
  setShowModal?: Dispatch<SetStateAction<boolean>>;
  allowClose?: boolean;
}

const Modal: React.FC<Props> = ({
  heading,
  children,
  logo,
  logoSrc,
  setShowModal,
  showModal,
  closeHandler,
}: Props) => {
  const modalRef = createRef<HTMLDivElement>();
  /**
   * This callback closes the model on Escape keypress
   */
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal && setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  /**
   * Adds animation to the modal
   */
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e: React.MouseEvent<HTMLElement>) => {
    if (modalRef.current === e.target) {
      setShowModal && setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal && (
        <ModalContainer ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalContent>
              <ModalHeading>
                {logo && (
                  <ModalLogo
                    className='full-logo'
                    src={`${
                      logoSrc
                        ? logoSrc
                        : 'https://storage.googleapis.com/pc-auth-assets/public/images/paperchain.svg'
                    }`}
                  />
                )}
                <h1>{heading}</h1>
                {setShowModal && (
                  <span aria-label='Close modal' onClick={closeHandler}>
                    <XIcon />
                  </span>
                )}
              </ModalHeading>
              {children}
            </ModalContent>
          </animated.div>
        </ModalContainer>
      )}
    </>
  );
};

const ModalHeading = styled.div`
  width: 100%;
  h1 {
    display: inline-block;
    font-size: var(--font-size-h5);
    width: 95%;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  span {
    display: inline-block;
    width: 5%;

    &:hover {
      cursor: pointer;
    }
    svg {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }
  }
  h1,
  span {
    margin: 0;
  }
`;

const ModalContainer = styled.div`
  align-items: center;
  backdrop-filter: blur(32px);
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const ModalContent = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: var(--border-radius-base);
  box-shadow: var(--drop-shadow-base);
  justify-content: center;
  margin: auto;
  max-width: 500px;
  min-height: 250px;
  padding: var(--spacing-lg);
  width: 100%;

  @media screen and (min-width: 700px) {
    box-shadow: var(--drop-shadow-lg);
  }

  @media screen and (max-width: 500px) {
    max-width: 95%;
  }

  h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin: 5px 0;
    color: var(--color-grey-9);
  }

  p {
    display: block;
    color: var(--color-grey-5);
    font-size: 18px;
    line-height: 24px;
    margin: 15px 0;

    svg {
      color: var(--color-green-5);
      vertical-align: middle;
      width: 22px;
    }
  }

  a {
    color: var(--color-green-5);
  }

  form {
    margin: 15px 0;

    input {
      margin: var(--spacing-xs) 0;
      &[type='radio'] {
        margin: var(--spacing-sm);
      }
    }
  }

  @media screen and (max-width: 500px) {
    input,
    label,
    p,
    span {
      font-size: 14px;
    }
  }
`;

export const ModalButtons = styled.div`
  display: flex;
  margin: 15px 0 0;
  justify-content: space-between;

  button {
    font-size: var(--font-body-base);
    height: 54px;
    max-width: 40%;
    min-width: 156px;
    padding-top: 12px;

    &:first-child {
      color: var(--color-grey-6);
      background-color: transparent;
      text-align: left;
    }

    &:last-child {
      margin-left: auto;
      width: auto;
      text-align: center;
      color: var(--color-white);
      background-color: var(--color-black);
    }

    &:hover {
      cursor: pointer;
    }

    @media screen and (max-width: 380px) {
      min-width: 130px;
      font-size: 14px;
      padding: 10px 15px;
    }

    @media screen and (max-width: 340px) {
      min-width: 120px;
      font-size: 14px;
      padding: 10px;
    }
  }
`;

const ModalLogo = styled(Logo)`
  width: auto !important;
`;

export default Modal;
