import * as React from 'react';
import ReactContentLoader, { IContentLoaderProps } from 'react-content-loader';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  flex: 1;
`;

const ContentLoader: React.FC<IContentLoaderProps> = ({
  height,
  width,
  children,
  ...props
}) => {
  return (
    <Container>
      <ReactContentLoader
        width={width || '100%'}
        height={height || '100%'}
        {...props}
      >
        {children}
      </ReactContentLoader>
    </Container>
  );
};

export default ContentLoader;
