import * as React from 'react';
import { ReactComponent as EmptyViewIllustration } from './empty-view-illustration.svg';
import styled from 'styled-components';

interface Props {
  heading: String;
  description?: String;
  imageComponent?: React.ReactNode;
}

const ImageWrapper = styled.div`
  max-width: 200px;
`;

const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  color: var(--color-grey-5);
  text-align: center;
`;

const PlaceholderWrapper = styled.div`
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyViewPlaceholder: React.FC<Props> = ({
  heading,
  description,
  imageComponent = <EmptyViewIllustration />,
}: Props) => {
  return (
    <Container>
      <PlaceholderWrapper>
        <ImageWrapper>{imageComponent}</ImageWrapper>
        <h2>{heading}</h2>
        {description && <Description>{description}</Description>}
      </PlaceholderWrapper>
    </Container>
  );
};

export default EmptyViewPlaceholder;
