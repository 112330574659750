export const loadHeap = (heapKey: string) => {
  // @ts-ignore
  window.heap = window.heap || [];
  // @ts-ignore
  heap.load = function (e, t) {
    // @ts-ignore
    window.heap.appid = e;
    // @ts-ignore
    window.heap.config = t = t || {};
    var r = document.createElement('script');
    r.type = 'text/javascript';
    r.async = !0;
    r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
    var a = document.getElementsByTagName('script')[0];
    // @ts-ignore
    a.parentNode.insertBefore(r, a);
    for (
      var n = function (e: any) {
          return function () {
            // @ts-ignore
            heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        },
        p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
        ],
        o = 0;
      o < p.length;
      o++
    )
      // @ts-ignore
      heap[p[o]] = n(p[o]);
  };
  // @ts-ignore
  heap.load(heapKey);
};
