import styled from 'styled-components';
import { Link } from '../Links';

export const Button = styled(Link)`
  font-size: 1rem;
  background: var(--color-white);
  border-radius: 8px;
  color: #000;
  flex-grow: 0;
  flex: none;
  flex: none;
  margin: 10px auto;
  padding: 12px 24px;
  order: 2;
  text-decoration: none;
  display: inline-flex;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

export const SendMoneyButton = styled(Button)`
  color: var(--color-white);
  height: 48px;
  width: 178px;
  background-color: var(--color-green-6);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }

  svg {
    vertical-align: text-top;
    margin: 1px 5px;
  }
`;
