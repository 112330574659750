import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MainContainer, CreditCard } from 'Components';
import CardContainer, { CardHolderContainer } from '../CardContainer';
import { selectCardReferences, selectCards } from '../cardsSlice';
import { selectAccount } from 'features';

import { useAppSelector } from 'hooks';

interface Props {
  account: string;
  active: Boolean;
  primaryCard: string;
}

const Cards: React.FC<Props> = () => {
  const primaryCard = useAppSelector(selectCardReferences);
  const { cards } = useAppSelector(selectCards);
  const { status: accountStatus, data: account } = useAppSelector(
    selectAccount,
  );

  const history = useHistory();
  if (accountStatus === 'succeeded' && !account.is_active) {
    history.push('/');
  }

  return (
    <MainContainer style={{ minHeight: '70vh' }}>
      <H1>Your Cards</H1>

      {cards.length === 0 && (
        <CardHolderContainer>
          <CreditCard loading={true} />
        </CardHolderContainer>
      )}
      {cards &&
        cards.map((item, index) => {
          return (
            <CardContainer
              key={index}
              primaryCardId={primaryCard.cards.card_id}
              cardDetails={item}
            />
          );
        })}
    </MainContainer>
  );
};

const H1 = styled.h1`
  color: var(--color-black);
  grid-column: span 12 / span 12;
  height: 30px;
  margin: 0 0 10px;
  padding: 30px 0;
  @media screen and (max-width: 400px) {
    padding-left: 20px;
  }
`;

export default Cards;
