import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { Section, ListLoader } from 'Components';
import { useAppSelector } from 'hooks';
import {
  selectAccount,
  selectFinancialAccount,
  SendMoney,
  Payback,
} from 'features';
import { RequestStatus } from 'features/transactions/transactionTypes';
import { FinancialAccount } from 'features/account/financialAccount/financialAccountTypes';
import { Account } from 'features/account/accountTypes';

interface Props {
  accountStatus: RequestStatus;
  account: Account;
  financialAccount: FinancialAccount;
  financialAccountStatus: RequestStatus;
}

const Payments: React.FC<Props> = () => {
  const financialAccount = useAppSelector(selectFinancialAccount);
  const account = useAppSelector(selectAccount);

  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberLast4, setAccountNumberLast4] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [showAccount, setShowAccount] = useState(false);

  const history = useHistory();

  if (account.status === 'succeeded' && !account.data.is_active) {
    history.push('/');
  }

  useEffect(() => {
    if (financialAccount.data.financial_addresses.length === 0) {
      return;
    }
    const {
      account_number,
      account_number_last4,
      routing_number,
    } = financialAccount.data.financial_addresses[0].aba;
    setAccountNumber(account_number);
    setAccountNumberLast4(account_number_last4);
    setRoutingNumber(routing_number);
  }, [account, financialAccount]);

  const handleReveal = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setShowAccount(!showAccount);
  };

  return (
    <Wrapper style={{ display: 'block', minHeight: '70vh' }}>
      <Container className='grid'>
        <Section
          className='col-span-4 sm-col-span-full lg-col-span-6'
          style={{ height: 'fit-content' }}
        >
          <H1>Payments</H1>
          {(financialAccount.status === 'idle' ||
            financialAccount.status === 'loading') && (
            <ListLoader height={240} />
          )}
          {financialAccount.status === 'succeeded' && routingNumber === '' ? (
            <p>Your financial account data will be listed here</p>
          ) : (
            financialAccount.status === 'succeeded' &&
            routingNumber !== '' && (
              <>
                <H3>Account Details</H3>
                <H5>Routing Number</H5>
                <p>{routingNumber}</p>
                <H5>Account Number</H5>
                <span>
                  {!showAccount ? (
                    <>
                      {new Array(
                        (accountNumber && accountNumber.length) || 4 - 4,
                      ).join('*')}
                      {accountNumberLast4}
                    </>
                  ) : (
                    <>{accountNumber && accountNumber}</>
                  )}
                  <Icon onClick={handleReveal} href='/'>
                    {!showAccount ? <EyeIcon /> : <EyeOffIcon />}
                  </Icon>
                </span>
                <H5>Bank Name</H5>
                <p>Evolve Bank and Trust</p>
              </>
            )
          )}
        </Section>
        <SendMoney />
        <Payback payeeAccountId={account.data.id} />
      </Container>
    </Wrapper>
  );
};

const H1 = styled.h1`
  margin: 0 0 10px;
  padding: 30px 0;
`;

const H3 = styled.h3`
  margin: 0 0 10px;
  padding: 10px 0;
`;

const H5 = styled.h5`
  margin: 0 0 3px;
  padding: 5px 0;
`;

const Icon = styled.a`
  color: var(--color-white);
  padding: 15px 15px 5px;
  vertical-align: text-top;
  margin: 8px;
  &:hover {
    background-color: var(--color-grey-10);
    border-radius: var(--border-radius-sm);
    color: var(--color-green-6);
  }
  svg {
    width: 22px;
    height: 22px;
    color: var(--color-grey-8);
  }
`;

const Wrapper = styled.div`
  max-width: var(--breakpoint-lg);
  padding: var(--spacing-xl);
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    padding: var(--spacing-base);
  }
`;

const Container = styled.div`
  margin: -110px auto auto;
  min-height: 80vh;
  @media screen and (max-width: 992px) {
    margin: -60px auto auto;
  }
  @media screen and (max-width: 800px) {
    margin: -90px auto 0;
  }
`;

export default Payments;
