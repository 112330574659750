import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import CardDigits from './CardDigits';
import SetPin from './SetPin';

interface Props {
  cardId: string;
  close: (e: React.MouseEvent<HTMLElement>) => void;
  header?: string;
  last_4: string;
  setHeader: Dispatch<SetStateAction<string>>;
}

const ActivateCard: FC<Props> = ({
  cardId,
  close,
  header,
  last_4,
  setHeader,
}: Props) => {
  const [pinProcessed, setPinProcessed] = useState(false);
  useEffect(() => {
    if (header) {
      setHeader(header);
    }
  }, [header, setHeader]);

  return (
    <>
      {pinProcessed ? (
        <SetPin
          cardId={cardId}
          close={close}
          header={'Set your PIN'}
          pinFieldPlaceholder={'Set a PIN'}
          setHeader={setHeader}
          setProcessed={setPinProcessed}
          successHeader='Your card has been activated'
        />
      ) : (
        <CardDigits
          setProcessed={setPinProcessed}
          last_4_digits={last_4}
          close={close}
          setHeader={setHeader}
          header={header}
        />
      )}
    </>
  );
};

ActivateCard.defaultProps = {
  header: 'Activate Card',
};

export { CardDigits, SetPin };
export default ActivateCard;
