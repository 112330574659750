import React, { createRef, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { selectAccount } from 'features';
import { useAppSelector } from 'hooks';
import { NavLink } from 'Components';
import { Logo } from 'app-components';
import { appIconPath } from 'settings';
import './index.scss';

interface navs {
  name: string;
  path: string;
  icon?: string;
}

interface Props {
  active: Boolean;
  navigation: navs[];
}

export const NavBar: React.FC<Props> = ({ active, navigation }: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [path, setPath] = useState('');
  const { logout, user } = useAuth0();

  const account = useAppSelector(selectAccount);
  const location = useLocation();
  const history = useHistory();
  const ddmRef = createRef<HTMLButtonElement>();
  const match = useRouteMatch({
    path: '/',
    strict: true,
  });
  const paperchainLogoUrl = `${appIconPath}paperchain.svg`;

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const isMenuOpen = Boolean(menuAnchor);

  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    logout({ returnTo: window.location.origin });
  };

  const gotoSettings = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    history.push('/account/settings');
  };

  const openProfileMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setMenuAnchor(ddmRef.current);
  };

  const handleProfileMenuClose = () => {
    setMenuAnchor(null);
  };

  const renderMenu = (
    <Menu
      getContentAnchorEl={null}
      anchorEl={menuAnchor}
      keepMounted
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      elevation={0}
      open={isMenuOpen}
      style={{ marginTop: '45px' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      onClose={handleProfileMenuClose}
    >
      {account.data.is_active && (
        <MenuItem>
          <Button
            href='/account/settings'
            id='user-settings-btn'
            onClick={gotoSettings}
            className='ddmButton'
          >
            Settings
          </Button>
        </MenuItem>
      )}
      <MenuItem>
        <Button
          className='ddmButton'
          href='/'
          id='user-logout-btn'
          onClick={handleLogout}
        >
          Logout
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <NavMenu>
      <AppBar position='static' className='menu'>
        <Toolbar className='top-bar'>
          {match?.isExact ? (
            <AppLogo className='full-logo' src={paperchainLogoUrl} />
          ) : (
            <Link to='/'>
              <AppLogo className='full-logo' src={paperchainLogoUrl} />
            </Link>
          )}
          <div className='sectionDesktop'>
            {user && (
              <Button
                id='user-account-icon'
                ref={ddmRef}
                className='profile-button'
                onClick={openProfileMenu}
              >
                <AppLogo src={user.picture} />
              </Button>
            )}
          </div>
        </Toolbar>
        <hr className='nav-hr-separator' />
        {renderMenu}
        <Toolbar className='top-bar'>
          {active ? (
            <>
              {navigation.map((item, index) => (
                <MenuItem
                  key={index}
                  className={`menu-item ${path === item.path ? 'active' : ''}`}
                >
                  <NavLink
                    key={index}
                    to={item.path}
                    className='nav-button'
                    color='inherit'
                  >
                    {item.name}
                  </NavLink>
                </MenuItem>
              ))}
            </>
          ) : (
            <MenuItem className={`menu-item ${path === '/' ? 'active' : ''}`}>
              <NavLink to='/' className='nav-button' color='inherit'>
                Home
              </NavLink>
            </MenuItem>
          )}
        </Toolbar>
      </AppBar>
    </NavMenu>
  );
};

const AppLogo = styled(Logo)`
  border-radius: var(--border-radius-round);
`;

const NavMenu = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: var(--color-black);
  height: 250px;
  @media screen and (max-width: 991px) {
    max-height: 190px;
  }
`;
