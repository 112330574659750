import { InitialState } from './accTypes';

export const initialState: InitialState = {
  status: 'idle',
  error: null,
  data: {
    id: null,
    charges_enabled: false,
    company: {
      address: {
        city: '',
        country: 'US',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
      },
      directors_provided: false,
      executives_provided: false,
      name: '',
      owners_provided: false,
      phone: '',
      tax_id_provided: false,
      verification: {
        document: {
          back: '',
          details: '',
          details_code: '',
          front: '',
        },
      },
    },
    requirements: {
      current_deadline: '',
      currently_due: [],
      disabled_reason: '',
      errors: [],
      eventually_due: [],
      past_due: [],
      pending_verification: [],
    },
    country: 'US',
    default_currency: 'US',
    email: '',
    payouts_enabled: false,
    type: 'custom',
  },
};
