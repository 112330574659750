import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Button,
  ErrorContainer,
  ErrorMessage,
  FormInput,
} from 'app-components';
import { ModalButtons } from 'Components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectAccessToken, selectAccount } from 'features';
import { sleep } from 'utils';
import { updateCard } from '../';

const schema = yup.object().shape({
  reason: yup.string().required('Please select a reason to cancel this card.'),
  verify: yup
    .string()
    .required('Type CANCEL to confirm you want to cancel this card.')
    .matches(/CANCEL/, 'Type CANCEL to confirm you want to cancel this card.'),
});

interface data {
  reason: string;
  varify: string;
}

interface Props {
  cardId: string;
  close: (e: React.MouseEvent<HTMLElement>) => void;
  goBack: (e: React.MouseEvent<HTMLElement>) => void;
  header: string;
  setHeader: Dispatch<SetStateAction<string>>;
}

const CancelCard: FC<Props> = ({
  cardId,
  close,
  goBack,
  header,
  setHeader,
}: Props) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const accessToken = useAppSelector(selectAccessToken);
  const [success, setSuccess] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ resolver: yupResolver(schema) });

  const cancelCard = (data: data) => {
    setSuccess(true);
    if (!accessToken) {
      console.error(
        'Failed to cancel card because access token was not in store',
      );
      return;
    }
    sleep(2000).then(async () => {
      dispatch(
        updateCard({
          connectedAccount: account.data.account_id,
          id: cardId,
          params: {
            cancellation_reason: data.reason,
            status: 'canceled',
          },
          token: accessToken,
        }),
      );
    });
  };

  useEffect(() => {
    setHeader(header);
  });

  return (
    <>
      {success ? (
        <>
          <h5>We’ve canceled your card. A new one is on the way.</h5>
          <p>We will send a new card to the address on file.</p>
          <ModalButtons>
            <Button onClick={close}>Close</Button>
            <Button onClick={close}>Okay</Button>
          </ModalButtons>
        </>
      ) : (
        <>
          <h5>Tell us why you need a new card.</h5>
          <p>
            We’ll immediately block your card if you report your card as lost,
            damaged or stolen. This action cannot be undone.
          </p>
          <form onSubmit={handleSubmit(cancelCard)}>
            <FormInput
              error={errors.reason}
              id='lost'
              label='My card is lost or damaged'
              name='reason'
              register={register}
              type='radio'
            />
            <FormInput
              error={errors.reason}
              id='stolen'
              label='My card was stolen'
              name='reason'
              register={register}
              type='radio'
            />
            <span style={{ color: 'red' }}>
              Note: canceling a card is an irreversible process.
              <br />
              Type CANCEL in the field below to confirm that you want to cancel
              this card.
            </span>
            <FormInput
              error={errors.verify}
              id='cancel'
              label='Verify Cancel'
              name='verify'
              register={register}
              placeholder='CANCEL'
              type='text'
            />
            <ModalButtons>
              <Button type='reset' onClick={goBack}>
                Nevermind
              </Button>
              <Button>Continue</Button>
            </ModalButtons>
            <ErrorContainer show={errors.reason || errors.verify}>
              {errors.verify && (
                <ErrorMessage
                  style={{ color: 'var(--color-error-red)', fontSize: '16px' }}
                >
                  {errors.verify.message}
                </ErrorMessage>
              )}{' '}
              {errors.reason && (
                <ErrorMessage
                  style={{ color: 'var(--color-error-red)', fontSize: '16px' }}
                >
                  Please select one
                </ErrorMessage>
              )}{' '}
            </ErrorContainer>
          </form>
        </>
      )}
    </>
  );
};

CancelCard.defaultProps = {
  header: 'Cancel Card',
};

export default CancelCard;
