import React from 'react';
import { CreditCard } from 'Components';
import { selectCardById, selectCardReferences } from '../cardsSlice';
import { months } from '../cardTypes';

import { selectFinancialAccount } from '../../account';

import { useAppSelector } from 'hooks';

const PrimaryCard: React.FC = () => {
  // Redux Selectors
  const cardReferences = useAppSelector(selectCardReferences);
  const card = useAppSelector((state) =>
    selectCardById(state, cardReferences.cards.card_id),
  );

  const financialAccount = useAppSelector(selectFinancialAccount);

  // @ts-ignore
  const bal = financialAccount.data.balance.cash.available.usd;
  const last4 = card?.pin ? card?.last4 : '****';
  const cardNum = ['**** **** ****', last4].join(' ');

  const exp = [
    // @ts-ignore
    months[card?.exp_month],
    card?.exp_year.toString().slice(2),
  ].join('/');

  return (
    <>
      <CreditCard
        // @ts-ignore
        balance={bal / 100}
        balanceLabel='Current Balance'
        cardNumber={cardNum}
        cvc='***'
        expire={exp}
        loading={!card}
      />
    </>
  );
};

export default PrimaryCard;
