import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Logo } from 'app-components';
import { Button, Loading } from 'Components';
import { Footer } from 'app-components';
import { NotAValidLink, PageNotFound } from '../../Error';
import { useSearchParam } from 'react-use';

interface Props {
  loading?: boolean;
  notFound?: boolean;
  error?: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setNotFound: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<boolean>>;
}

const api = process.env.REACT_APP_PURA_API;

const WelcomePage: React.FC<Props> = ({
  error,
  loading,
  notFound,
  setError,
  setLoading,
  setNotFound,
}: Props) => {
  // TODO: Set to empty string
  const [vendor, setVendor] = useState('');
  const [vendorId, setVendorId] = useState('');
  // @ts-ignore
  const { id } = useParams();
  const email = useSearchParam('email');

  useEffect(() => {
    const vendor = atob(unescape(encodeURIComponent(id)));
    fetch(`${api}/invite/${vendor}`)
      .then((response) => {
        setLoading(false);
        if (response.status === 404) {
          setError(true);
        } else if (response.status >= 500) {
          setNotFound(true);
        }
        return response.json();
      })
      .then((data) => {
        if (data.Data) {
          setVendor(data.Data['name']);
          setVendorId(data.Data['uuid']);
        }
      });
  });

  if (loading) {
    return <Loading />;
  }

  if (notFound) {
    return <PageNotFound />;
  }

  if (error) {
    return <NotAValidLink />;
  }

  return (
    <>
      <Container className='grid'>
        <CardContainer className='col-span-4 sm-col-span-full'>
          <CardGradient />
          <CardContent>
            <Logo
              style={{
                width: '56px',
                height: '51.69px',
              }}
              src={
                'https://storage.googleapis.com/pc-auth-assets/public/images/pc.svg'
              }
            />
            {vendor === 'UnitedMasters' ? (
              <>
                <span style={{ margin: '0 var(--spacing-md)' }}>+</span>
                <Logo
                  style={{
                    width: '56px',
                    height: '44.69px',
                  }}
                  src={
                    'https://storage.googleapis.com/pc-auth-assets/public/images/um.svg'
                  }
                />
              </>
            ) : null}
          </CardContent>
        </CardContainer>
        <Content className='col-span-8 sm-col-span-full'>
          <Description>
            <H1>Get started with paperchain</H1>
            <P>{vendor} would like to get you paid daily.</P>
            <Button
              style={{ margin: '0' }}
              href={`/auth/sign-up?link=${vendor}&identity=${vendorId}&email=${email}`}
            >
              Sign up
            </Button>
          </Description>
        </Content>
        <div className='col-span-full' style={{ alignSelf: 'end' }}>
          <Footer links={false} social={false} />
        </div>
      </Container>
    </>
  );
};

WelcomePage.defaultProps = {
  error: false,
  loading: false,
  notFound: false,
};

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: var(--breakpoint-lg);
  margin: 0 auto;
  padding: 0 var(--spacing-xl);
  min-height: 100vh;

  @media screen and (max-width: 48rem) {
    padding: 0;
  }
`;

const Content = styled.div`
  background: var(--color-black);
  border-radius: var(--border-radius-base);
  box-shadow: var(--drop-shadow-lg);
  transform: translateY(15%) translateX(-16.66%);
  padding: calc(var(--spacing-xl) * 2) calc(var(--spacing-xl) * 2)
    calc(var(--spacing-xl) * 2) calc(var(--spacing-xl) * 6);
  padding-top: 100px;
  padding-bottom: 170px;

  @media screen and (max-width: 48rem) {
    width: 100%;
    border-radius: 0;
    padding: calc(var(--spacing-xl) * 4) var(--spacing-base);
    transform: translateY(-15%);
  }
`;

const CardContainer = styled.div`
  max-width: 400px;
  width: 100%;
  color: var(--color-white);
  font-size: var(--font-size-h2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: relative;
  align-self: stretch;
  transform: translateY(15%) translateX(16.66%);

  @media screen and (max-width: 48rem) {
    margin: 0 auto;
    transform: translateY(15%);
  }
`;

const CardGradient = styled.div`
  width: 100%;
  height: 0;
  padding-top: calc(260 / 400 * 100%);
  background: var(--paperchain-gradient);
  border-radius: var(--border-radius-base);
  box-shadow: var(--drop-shadow-lg);
`;

const CardContent = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const H1 = styled.h1`
  font-family: var(--font-family-mono);
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight);
  text-transform: uppercase;
  line-height: 1;

  @media screen and (max-width: 991px) {
    font-size: var(--font-size-h4);
  }
`;

const Description = styled.div`
  color: var(--color-white);
  font-size: var(--font-size-body);
`;

const P = styled.p`
  line-height: 1.5;
  margin: var(--spacing-lg) 0;
`;

export default WelcomePage;
