import { environment, intercomId } from 'settings';

export const loadSupport = () => {
  (function () {
    var w = window;
    // @ts-ignore
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      // @ts-ignore
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        // @ts-ignore
        i.c(arguments);
      };
      // @ts-ignore
      i.q = [];
      // @ts-ignore
      i.c = function (args) {
        // @ts-ignore
        i.q.push(args);
      };
      // @ts-ignore
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = `https://widget.intercom.io/widget/${intercomId}`;
        var x = d.getElementsByTagName('script')[0];
        // @ts-ignore
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
        // @ts-ignore
      } else if (w.attachEvent) {
        // @ts-ignore
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const updateSupport = () => {
  // @ts-ignore
  window && window.Intercom && window.Intercom('update');
};

export const bootSupport = (options = {}) => {
  window &&
    // @ts-ignore
    window.Intercom &&
    // @ts-ignore
    window.Intercom('boot', { app_id: intercomId, ...options });
};

export const loadSupportInProduction = (options = {}) => {
  if (environment.toLocaleLowerCase() === 'production') {
    // Let's load support
    loadSupport();
    updateSupport();
    // @ts-ignore
    bootSupport({
      created_at: new Date().getTime(),
      ...options,
    });
  }
};
