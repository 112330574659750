import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button } from 'app-components';
import { CircularProgress } from '@material-ui/core/';
import { ModalButtons } from 'Components';
import { selectAccessToken, selectAccount } from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sleep } from 'utils';
import { updateCard } from '../';

interface Props {
  cardId: string;
  close: (e: React.MouseEvent<HTMLElement>) => void;
  goBack: (e: React.MouseEvent<HTMLElement>) => void;
  setHeader: Dispatch<SetStateAction<string>>;
}

const LockCard: React.FC<Props> = ({
  cardId,
  close,
  goBack,
  setHeader,
}: Props) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const accessToken = useAppSelector(selectAccessToken);

  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setHeader('Lock Card');
  });

  const lockCard = () => {
    if (!accessToken) {
      console.error(
        'Failed to lock card because access token was not in store',
      );
      return;
    }

    setProcessing(true);
    sleep(2000).then(async () => {
      await dispatch(
        updateCard({
          connectedAccount: account.data.account_id,
          id: cardId,
          params: {
            status: 'inactive',
          },
          token: accessToken,
        }),
      );
      setSuccess(true);
      setProcessing(false);
    });
  };

  return (
    <>
      {success ? (
        <>
          <h5>Success</h5>
          <p>Your card has been locked</p>
          <p>
            You may reverse this process by clicking on the unlock button below
            your card
          </p>
          <ModalButtons>
            <Button onClick={close}>Close</Button>
          </ModalButtons>
        </>
      ) : (
        <>
          <h5>What happens when I lock my card?</h5>
          <p>
            When you lock your card you temporarily block your ATM withdrawals,
            card payments, and online purchases. You can instantly lock and
            unlock your card to protect yourself against unauthorized
            transactions
          </p>
          <ModalButtons>
            <Button type='reset' onClick={goBack}>
              Cancel
            </Button>
            <Button onClick={lockCard} disabled={processing}>
              {processing ? (
                <CircularProgress
                  style={{
                    color: '#fff',
                    height: '20px',
                    width: '20px',
                    margin: '0 5px 0 0',
                  }}
                />
              ) : (
                'Lock my card'
              )}
            </Button>
          </ModalButtons>
        </>
      )}
    </>
  );
};

export default LockCard;
