import React from 'react';
import styled from 'styled-components';
import { Footer, Logo } from 'app-components';

const SuccessPage: React.FC = () => {
  return (
    <>
      <Background />
      <div style={{ position: 'relative', minHeight: '85vh' }}>
        <div
          style={{
            position: 'absolute',
            top: '20%',
            zIndex: 999,
            margin: 'auto',
            color: '#fff',
            textAlign: 'center',
            minWidth: '100%',
          }}
        >
          <Logo
            style={{ width: '200px', height: '30px', display: 'inline' }}
            alt="Paperchain's logo"
            src='https://storage.googleapis.com/pc-auth-assets/public/images/logo-pc-white.svg'
          />
          <h1 style={{ margin: '30px auto' }}>Success</h1>
          <h3 style={{ margin: '30px auto 60px' }}>
            A verification email has been sent to your inbox{' '}
          </h3>
          <HomeButton href='/'>Login</HomeButton>
        </div>
      </div>
      <FooterContent style={{ color: '#fff !important' }}>
        <Footer social={false} links={false} />
      </FooterContent>
    </>
  );
};

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;
  background: radial-gradient(
      87.14% 114.3% at 66.13% 12.86%,
      #34d399 0%,
      rgba(147, 223, 196, 0) 100%
    ),
    linear-gradient(180deg, #f472b6 0%, #fbbf24 100%);
`;

const HomeButton = styled.a`
  padding: 20px 30px;
  color: #fff;
  background-color: #000;
  border-radius: var(--border-radius-sm);
  margin-top: 20px;
`;

const FooterContent = styled.div`
  min-height: '15vh';
  p {
    color: #fff;
  }
`;

export default SuccessPage;
