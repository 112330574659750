import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CircularProgress } from '@material-ui/core/';
import {
  Button,
  ErrorContainer,
  ErrorMessage,
  FormInput,
} from 'app-components';
import { ModalButtons } from 'Components';
import { selectAccessToken, selectAccount } from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';
import { encryptPin, sleep } from 'utils';
import { updateCard } from '../';

const schema = yup.object().shape({
  pin: yup
    .string()
    .required('Four digits are required for your pin')
    .max(4, 'Pin must be four digits long')
    .min(4, 'Pin must be four digits long')
    .matches(/^[+-]?\d*$/, 'Only numbers are allowed for pins'),
  confirmPin: yup
    .string()
    .required('Please confirm your pin')
    .oneOf([yup.ref('pin'), null], 'Pins must match'),
});

interface FormInputs {
  pin: string;
  confirmPin: string;
}

interface Props {
  cardId: string;
  close: (e: React.MouseEvent<HTMLElement>) => void;
  description?: string;
  descriptionHeading?: string;
  goBack?: (e: React.MouseEvent<HTMLElement>) => void;
  header: string;
  pinFieldPlaceholder: string;
  setHeader: Dispatch<SetStateAction<string>>;
  setProcessed?: Dispatch<SetStateAction<boolean>>;
  successHeader: string;
}

const SetPin: FC<Props> = ({
  cardId,
  close,
  description,
  descriptionHeading,
  goBack,
  header,
  pinFieldPlaceholder,
  setHeader,
  setProcessed,
  successHeader,
}: Props) => {
  const dispatch = useAppDispatch();
  const account = useAppSelector(selectAccount);
  const accessToken = useAppSelector(selectAccessToken);
  const [processing, setProcessing] = useState(false);
  const [suceeded, setSuceeded] = useState(false);
  const [error, setError] = useState('');
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({ resolver: yupResolver(schema) });

  const handleActivate = (data: FormInputs) => {
    // TODO: Set this to only have 4 tries
    setProcessing(true);
    setError('');
    if (!accessToken) {
      console.error('Failed to set pin because access token was not in store');
      return;
    }
    sleep(1000).then(async () => {
      const encryptedPin = await encryptPin(data.pin, 'compact');
      await dispatch(
        updateCard({
          connectedAccount: account.data.account_id,
          id: cardId,
          params: {
            pin: {
              encrypted_number: encryptedPin,
            },
            status: 'active',
          },
          token: accessToken,
        }),
      );
      setHeader(successHeader);
      setSuceeded(true);
      setProcessing(false);
    });
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    setError('');
  };

  useEffect(() => {
    setHeader(header);
  }, [header, setHeader]);

  return (
    <>
      {suceeded ? (
        <>
          <h5>Congratulations!</h5>
          <p>
            You may now begin using your card for purchases and withdrawals.
          </p>
          <ModalButtons>
            <Button type='reset' onClick={close}>
              Close
            </Button>
            <Button type='submit' onClick={close}>
              Okay
            </Button>
          </ModalButtons>
        </>
      ) : (
        <>
          <h5>{descriptionHeading}</h5>
          <p>{description}</p>
          <form onSubmit={handleSubmit(handleActivate)} method='post'>
            <input
              type='text'
              name='email'
              autoComplete='username email'
              style={{ display: 'none' }}
            />
            <FormInput
              autocomplete='new-password'
              error={errors.pin}
              handleChange={handleChange}
              id='pin'
              label='Set Pin'
              name='pin'
              placeholder={pinFieldPlaceholder}
              register={register}
              type='password'
            />
            <FormInput
              error={errors.confirmPin}
              handleChange={handleChange}
              id='confirmPin'
              label='Confirm PIN'
              name='confirmPin'
              placeholder='Confirm PIN'
              register={register}
              type='password'
              autocomplete='new-password'
            />
            <ModalButtons>
              <Button
                type='reset'
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  if (setProcessed) setProcessed(false);
                  else if (goBack) goBack(e);
                }}
              >
                Cancel
              </Button>
              <Button type='submit' disabled={processing}>
                {processing ? (
                  <CircularProgress
                    style={{
                      color: '#fff',
                      height: '20px',
                      width: '20px',
                      margin: '0 5px 0 0',
                    }}
                  />
                ) : (
                  'Set PIN'
                )}
              </Button>
            </ModalButtons>
          </form>

          {/* TODO: loop through errors and print each one */}
          <ErrorContainer
            id='error-message'
            show={errors.pin || errors.confirmPin || error}
          >
            {errors.pin && (
              <ErrorMessage
                style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
              >
                {errors.pin.message}
              </ErrorMessage>
            )}
            {errors.confirmPin && (
              <ErrorMessage
                style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
              >
                {errors.confirmPin.message}
              </ErrorMessage>
            )}
            {error && !errors.pin && (
              <ErrorMessage
                style={{ color: 'var(--color-red-err)', fontSize: '16px' }}
              >
                {error}
              </ErrorMessage>
            )}
          </ErrorContainer>
        </>
      )}
    </>
  );
};

SetPin.defaultProps = {
  description: 'Create and confirm your 4-digit PIN code.',
  descriptionHeading: 'Create a PIN for your card.',
  pinFieldPlaceholder: 'Set a PIN',
};

export default SetPin;
