import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Loading } from 'Components';
import { maskEmail } from 'utils';

const VerifyEmail = () => {
  const history = useHistory();
  const { user } = useAuth0();
  const email = maskEmail(user.email);
  useEffect(() => {
    let timeoutId = setInterval(() => {
      history.push('/');
    }, 10000);
    return () => clearInterval(timeoutId);
  });

  return (
    <FullPage>
      <MessageContainer>
        <h1>Please verify your email</h1>
        <Message>
          Please follow the instructions in the email sent to {email} in order
          to verify this email address belongs to you.
        </Message>
      </MessageContainer>
      <Loading />
    </FullPage>
  );
};

const FullPage = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Message = styled.p`
  font-size: 25px;
`;

const MessageContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  top: 30%;
  text-align: center;
`;

export default VerifyEmail;
