import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CreditCard } from 'Components';
import CardSettings from '../CardSettings';
import { Card, months } from '../cardTypes';

interface Props {
  primaryCardId: string;
  cardDetails: Card;
}

const CardContainer: FC<Props> = ({ primaryCardId, cardDetails }: Props) => {
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (cardDetails.id === primaryCardId) {
      setDescription('Primary');
    } else {
      setDescription('Personal');
    }
  }, [description, cardDetails.id, primaryCardId]);

  const { pin, status, last4, exp_month, exp_year } = cardDetails;

  const padder = '**** **** ****';
  const concealer = '****';
  // @ts-ignore
  const exp = [months[exp_month], exp_year.toString().slice(2)].join('/');
  return (
    <CardHolderContainer>
      <CreditCard
        cardNumber={`${
          pin || status === 'canceled'
            ? [padder, last4].join(' ')
            : [padder, concealer].join(' ')
        }`}
        cvc='***'
        expire={exp}
      />
      <CardSettings description={description} cardDetails={cardDetails} />
    </CardHolderContainer>
  );
};

export const CardHolderContainer = styled.div`
  background-color: var(--color-white);
  margin: var(--spacing-sm);
  padding: var(--spacing-lg);
  grid-column: span 4 / span 2;
  min-height: 45vh;
  @media screen and (max-width: 1354px) {
  }
  @media screen and (max-width: 1254px) {
    grid-column: span 6 / span 6;
  }
  @media screen and (max-width: 800px) {
    grid-column: span 12 / span 12;
    max-width: 500px;
    min-width: 450px;
    margin: auto;
  }
  @media screen and (max-width: 500px) {
    grid-column: span 12 / span 12;
    max-width: 500px;
    min-width: initial;
    margin: initial;
    padding: 5px;
  }
`;

export default CardContainer;
