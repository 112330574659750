import React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Slider } from 'app-components';
import { updateNotification, selectNotification } from './';
import { selectAccessToken } from '../auth';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(selectNotification);
  const accessToken = useAppSelector(selectAccessToken);

  const toggle = async () => {
    if (!accessToken) {
      console.error(
        'Failed to toggle notifications because access token was not in store',
      );
      return;
    }
    await dispatch(
      updateNotification({
        token: accessToken,
        emailStatus: !notification.notification.emailStatus,
      }),
    );
  };

  return (
    <div className='col-span-full sm-col-span-12'>
      <H3>Notification Settings</H3>
      <label
        style={{
          fontSize: '--font-size-body-lg',
          padding: '0 20px 0 0',
          marginTop: '20px',
        }}
      >
        Email Notification:
      </label>
      <Slider
        toggle_on={notification.notification.emailStatus}
        handleClick={toggle}
        handleChange={toggle}
        type='checkbox'
      />
    </div>
  );
};

const H3 = styled.h3`
  margin-bottom: 20px;
`;

export default Notifications;
