import React from 'react';
import ContentLoader from './ContentLoader';
import { IContentLoaderProps } from 'react-content-loader';

const BulletListLoader: React.FC<IContentLoaderProps> = (props) => {
  const rowHeight = 100;
  const width = props.width || '100%';

  const height = typeof props.height === 'number' ? props.height : 500;
  if (typeof props.height === 'string') {
    console.warn(
      'BulletListLoader accepts only numeric height prop in order to easily calculate rows.',
    );
  }

  function renderRow(index: number) {
    const y = index * rowHeight;
    return (
      <React.Fragment key={index}>
        <rect
          x='90'
          y={y + 25}
          rx='10'
          ry='10'
          width={`calc(${width} - 90px)`}
          height={rowHeight - 80}
        />
        <circle r='30' cx='30' cy={y + 35} />
      </React.Fragment>
    );
  }

  function renderRows() {
    const numberOfRows = Math.floor(height / rowHeight);
    const rows = [];

    for (let i = 0; i < numberOfRows; i++) {
      rows.push(renderRow(i));
    }
    return <>{rows}</>;
  }

  return (
    <ContentLoader height={height} width={width} {...props}>
      {renderRows()}
    </ContentLoader>
  );
};

export default BulletListLoader;
