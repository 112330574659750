import axios from 'axios';
import qs from 'qs';
import {
  bankingApi,
  stripeApi,
  stripeKey,
  stripeVersion,
  stripeIssuingKeyUrl,
} from 'settings';

const jose = require('node-jose');

const { href } = window.location;

export const stripeOptionsLinks = {
  headers: {
    authorization: `bearer ${stripeKey}`,
    'stripe-version': stripeVersion,
    'content-type': 'application/x-www-form-urlencoded',
  },
};

export const stripeAccOptions = {
  headers: {
    authorization: `bearer ${stripeKey}`,
    'stripe-version': stripeVersion,
    'content-type': 'application/x-www-form-urlencoded',
    'Stripe-Account': '',
  },
};

export const stripeOptions = {
  headers: {
    authorization: `bearer ${stripeKey}`,
    'stripe-version': stripeVersion,
    'content-type': 'application/x-www-form-urlencoded',
    'Stripe-Account': '',
  },
  params: {
    'expand[]': 'financial_addresses.aba.account_number',
  },
};

export const stripeOpts = {
  headers: {
    authorization: `bearer ${stripeKey}`,
    'stripe-version': stripeVersion,
    'content-type': 'application/x-www-form-urlencoded',
    'Stripe-Account': '',
  },
};

export const getVerifyBankingUrl = (accountId: string): string => {
  const { hostname, port, protocol } = window.location;
  return `${protocol}//${hostname}:${port}/verify_banking/${accountId}`;
};

export const stripeReturnObject = {
  account: '',
  refresh_url: href,
  return_urf: '',
  type: 'account_onboarding',
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export function getAccount(account: string, token: string): Promise<any> {
  return axios.get(`${bankingApi}/accounts/${account}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAccountData(account: string, finAcc: string): Promise<any> {
  stripeAccOptions.headers['Stripe-Account'] = account;
  return axios.get(
    `${stripeApi}/financial_accounts/${finAcc}`,
    stripeAccOptions,
  );
}

export function getAccountPerson(account: string, token: string): Promise<any> {
  stripeAccOptions.headers['Stripe-Account'] = account;
  stripeAccOptions.headers.authorization = `Bearer ${token}`;
  return axios.get(`${bankingApi}/accounts/${account}/persons`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getAccountTransactions(
  account: string,
  finAcc: string,
): Promise<any> {
  stripeOpts.headers['Stripe-Account'] = account;
  return axios.get(
    `${stripeApi}/transactions?financial_account=${finAcc}`,
    stripeOpts,
  );
}

export function getStripeEditLink(account: string) {
  const { hostname, protocol, href, port, pathname } = window.location;
  const params = {
    account: account,
    refresh_url: href,
    return_url: `${protocol}//${hostname}:${port}${pathname}`,
    type: 'account_update',
  };
  return axios.post(
    `${stripeApi}/account_links`,
    qs.stringify(params),
    stripeOptionsLinks,
  );
}

export const getStripeLink = async (
  url: string,
  account_id: string,
  type: string,
) => {
  const datObj = {
    account: account_id,
    refresh_url: url,
    return_url: `${url}verifybanking/${account_id}`,
    type,
  };
  const response = await axios.post(
    `${stripeApi}/account_links`,
    qs.stringify(datObj),
    stripeOptionsLinks,
  );
  return response;
};

export function getCardDetails(cardId: string, account: string) {
  stripeOpts.headers['Stripe-Account'] = account;
  return axios.get(`${stripeApi}/issuing/cards/${cardId}`, stripeOpts);
}

export function getCards(account: string) {
  stripeOpts.headers['Stripe-Account'] = account;
  return axios.get(`${stripeApi}/issuing/cards`, stripeOpts);
}

export const encryptPin = async (
  pin: string,
  format: 'compact' | 'flattened',
) => {
  let encryptedPin = '';
  const keyData = await fetch(stripeIssuingKeyUrl).then((r) => r.json());

  await jose.JWE.createEncrypt({ format }, keyData.jwk)
    .update(pin)
    .final()
    .then(function (result: string) {
      encryptedPin = result;
    });
  return encryptedPin;
};

export const maskCharAt = (str: string, index: number, replacement: string) => {
  return (
    str.substr(0, index) + replacement + str.substr(index + replacement.length)
  );
};

export const maskEmail = (email: string) => {
  for (let i = 0; i < email.lastIndexOf('.'); i++) {
    if (i === 0 || email.charAt(i) === '@') {
      i++;
    }
    email = maskCharAt(email, i, '*');
  }
  return email;
};
