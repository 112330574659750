import React from 'react';
import styled from 'styled-components';
import { Locked } from 'TemplateParts';
import { SendMoney } from 'features';
import { useAppSelector } from 'hooks';
import { selectAccount } from 'features';
import { RecentTransactions } from 'features';

const Home = () => {
  const account = useAppSelector(selectAccount);

  if (account.status === 'succeeded' && !account.data.is_active) {
    return <Locked />;
  }

  return (
    <React.Fragment>
      <Wrapper>
        <Container className='grid'>
          <SendMoney />
          <RecentTransactions />
        </Container>
      </Wrapper>
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  max-width: var(--breakpoint-lg);
  padding: var(--spacing-xl);
  margin: 0 auto;
  @media screen and (max-width: 992px) {
    padding: var(--spacing-base);
  }
`;

const Container = styled.div`
  margin: -110px auto auto;
  min-height: 80vh;
  @media screen and (max-width: 992px) {
    margin: -60px auto auto;
  }
  @media screen and (max-width: 800px) {
    margin: -90px auto 0px;
  }
`;

export default Home;
