import styled from 'styled-components';

interface Props {
  label?: string;
  text?: string;
}

const Label = styled.div`
  margin-top: var(--spacing-lg);
  font-size: var(--font-body-lg);
`;

const Text = styled.div`
  font-size: var(--font-body-lg);
  color: var(--color-grey-6);
  white-space: pre-line;
  line-height: 1.2;
`;

export const TextLabel: React.FC<Props> = ({ label, text }: Props) => {
  return (
    <>
      <Label>{label}</Label>
      <Text>{text}</Text>
    </>
  );
};
