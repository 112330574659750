import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Loading } from 'Components';
import {
  fetchAccount,
  fetchConnectedAccount,
  selectAccount,
  selectConnectedAccount,
  updateDetails,
} from 'features';

const VerifyBanking: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const account = useAppSelector(selectAccount);
  const connAccount = useAppSelector(selectConnectedAccount);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getAccessTokenSilently().then(async (token) => {
      await dispatch(fetchAccount({ token: token, userId: user.sub }));
      if (account.status === 'succeeded' && !account.data.details_filled) {
        if (connAccount.status === 'idle' || connAccount.status === 'failed') {
          await dispatch(
            fetchConnectedAccount({ id: account.data.account_id, token }),
          );
          await updateAccStatus(token);
        }
      }
    });
    history.push('/');
  });

  const updateAccStatus = async (token: string) => {
    let missingRequirements = false;

    const {
      currently_due,
      eventually_due,
      past_due,
      pending_verification,
      errors,
    } = connAccount.data.requirements;

    currently_due.forEach((item) => {
      if (item !== 'external_account') {
        missingRequirements = true;
      }
    });

    eventually_due.forEach((item) => {
      if (item !== 'external_account') {
        missingRequirements = true;
      }
    });

    past_due.forEach((item) => {
      if (item !== 'external_account') {
        missingRequirements = true;
      }
    });

    if (
      !missingRequirements &&
      pending_verification.length === 0 &&
      errors.length === 0
    ) {
      // Set details_filled on accounts to true
      await dispatch(
        updateDetails({
          accountId: account.data.account_id,
          token: token,
          userId: user.sub,
        }),
      );
    }
  };

  return <Loading />;
};

export default VerifyBanking;
