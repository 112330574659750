import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { remixApi as remixApiUrl } from 'settings';
import { Invoice } from './InvoiceType';
import { RootState } from '../index';

export const remixApi = createApi({
  reducerPath: 'remix',
  baseQuery: fetchBaseQuery({
    baseUrl: remixApiUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const { accessToken } = state.auth;
      if (!accessToken) {
        console.error(
          'Querying Remix API will fail because access token is not in store',
        );
      }
      headers.set('Authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInvoices: builder.query<Invoice[], number>({
      query: (id) => ({
        url: `payeeAccounts/${id}/invoices`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetInvoicesQuery } = remixApi;
export default remixApi;
