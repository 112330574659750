import React from 'react';
import styled from 'styled-components';
import { CreditCardBody, MainContainer } from 'Components';
import { H5 as Heading } from 'app-components';

interface Props {
  messageHead?: string;
  message?: string;
}

const Locked: React.FC<Props> = ({ message, messageHead }: Props) => {
  return (
    <Main>
      <CardContainer className='text-center'>
        <CreditCard>
          <img
            alt="Paperchain's Logo"
            src='https://storage.googleapis.com/pc-auth-assets/public/images/pc.svg'
          />
        </CreditCard>
        <H5>{messageHead}</H5>
        <Par>{message}</Par>
      </CardContainer>
    </Main>
  );
};

Locked.defaultProps = {
  message: 'Your account is pending verification',
  messageHead: 'Your card is on the way!',
};

const Main = styled(MainContainer)`
  min-height: 75vh;
`;

const CardContainer = styled.div`
  margin: auto;
  align-items: center;
  padding: calc(var(--spacing-xl) * 2);
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 550px) {
    padding: calc(var(--spacing-xl) * 2) var(--spacing-sm);
  }
  @media screen and (max-width: 768px) {
    grid-column: span 8 / span 8;
  }
  @media screen and (max-width: 991px) {
    grid-column: span 6 / span 6;
  }
  @media screen and (min-width: 992px) {
    grid-column: span 4 / span 4;
  }
`;

const H5 = styled(Heading)`
  font-family: var(--font-family-sans);
`;

const Par = styled.p`
  font-size: var(--font-size-body-lg);
  font-family: var(--font-family-sans);
`;

const CreditCard = styled(CreditCardBody)`
  width: 405px;
  max-width: 405px;
  height: 263px;
  display: grid;
  img {
    grid-column: span 4 / span 4;
    margin: auto;
    width: 65px;
    height: 65px;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: auto;
    min-height: 180px;
  }
`;

export default Locked;
