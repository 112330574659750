import React from 'react';
import styled from 'styled-components';
import { Logo, H4 } from 'app-components';

const NotAValidLink = () => {
  return (
    <GradientContainer>
      <Wrapper className='text-center'>
        <Logo
          alt="Paperchain's logo"
          src='https://storage.googleapis.com/pc-auth-assets/public/images/logo-pc-white.svg'
          style={{ width: '200px', margin: '0 auto' }}
        />
        <H4 as='h1' className='text-uppercase'>
          Uh oh, not a valid link
        </H4>

        <p>You've reached an invalid or expired link.</p>
        <p>
          Please contact your vendor, publisher or{' '}
          <a
            href='mailto:support@paperchain.io'
            rel='noreferrer'
            target='_blank'
          >
            support@paperchain.io
          </a>{' '}
          for support.
        </p>
      </Wrapper>
    </GradientContainer>
  );
};

const GradientContainer = styled.div`
  background: var(--paperchain-gradient);
  height: 100vh;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    line-height: 36px;
    margin-top: var(--spacing-lg);
    margin-bottom: calc(var(--spacing-xl) * 2);
  }

  p {
    font-size: var(--font-size-body);
    line-height: var(--spacing-lg);
  }

  p + p {
    margin-top: var(--spacing-md);
  }

  a,
  a:hover {
    color: var(--color-white);
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  max-width: 325px;
`;

export default NotAValidLink;
