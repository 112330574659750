import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { puraApi, bankingApi } from 'settings';
import { RootState } from 'store';
import { Args, CardReferenceInitialState, InitialState } from './cardTypes';

const initialState: InitialState = {
  status: 'idle',
  error: null,
  cards: [],
};

interface Argumnets {
  connAcc: string;
  token: string;
}

export const fetchCards = createAsyncThunk(
  'cards/fetchCards',
  async (args: Argumnets) => {
    const response = await axios.get(`${bankingApi}/cards/${args.connAcc}`, {
      headers: {
        Authorization: `Bearer ${args.token}`,
      },
    });
    return response.data.data;
  },
);

interface CardArgs {
  connectedAccount: string;
  id: string;
  params: {
    pin?: {
      encrypted_number: string;
    };
    cancellation_reason?: string;
    status?: string;
  };
  token: string;
}

export const updateCard = createAsyncThunk(
  'cards/updateCard',
  async (args: CardArgs) => {
    const response = await axios.put(
      `${bankingApi}/issuing/${args.connectedAccount}/card/${args.id}`,
      args.params,
      {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      },
    );
    return response.data;
  },
);

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    fetchCards: {
      reducer(state, action) {
        state.cards = action.payload;
      },
      prepare(payload) {
        return payload;
      },
    },
    fetchCardReferences: {
      reducer(state, action) {
        state.cards = action.payload;
      },
      prepare(payload) {
        return payload;
      },
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchCards.pending]: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    // @ts-ignore
    [fetchCards.fulfilled]: (state, action) => {
      state.error = '';
      state.status = 'succeeded';
      state.cards = action.payload;
    },
    // @ts-ignore
    [fetchCards.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
    // @ts-ignore
    [updateCard.fulfilled]: (state, action) => {
      const index = state.cards.findIndex(
        (card) => card.id === action.payload.id,
      );
      state.cards[index] = action.payload;
    },
  },
});

export const selectCards = (state: RootState) => state.cards;

// Find a card by it's id
export const selectCardById = (state: RootState, id: string) => {
  return state.cards.cards.find((card) => card.id === id);
};

const cardReferenceInitialState: CardReferenceInitialState = {
  status: 'idle',
  error: null,
  cards: {
    card_holder_id: '',
    card_id: '',
    created_at: '',
    created_by: '',
    id: -1,
    is_active: false,
    payee_id: '',
  },
};

export const fetchCardReferences = createAsyncThunk(
  'cards/fetchCardReferences',
  async (args: Args) => {
    const response = await axios.get(`${puraApi}/payee/card/${args.user}`, {
      headers: {
        Authorization: `Bearer ${args.token}`,
      },
    });
    return response.data.Data;
  },
);

const cardReferenceSlice = createSlice({
  name: 'cardReferences',
  initialState: cardReferenceInitialState,
  reducers: {
    fetchCardReferences(state, action) {
      state.cards = action.payload;
    },
    prepare(payload) {
      return payload;
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchCardReferences.pending]: (state) => {
      state.error = '';
      state.status = 'loading';
    },
    // @ts-ignore
    [fetchCardReferences.fulfilled]: (state, action) => {
      state.error = '';
      state.status = 'succeeded';
      state.cards = action.payload;
    },
    // @ts-ignore
    [fetchCardReferences.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const cardReferencesReducer = cardReferenceSlice.reducer;
export const selectCardReferences = (state: RootState) => state.cardReferences;

export default cardsSlice.reducer;
