import { Transactions } from 'features/transactions/transactionTypes';
import React from 'react';
import { TransactionListRow } from './TransactionListRow';
import styled from 'styled-components';

interface Props {
  transactions: Transactions[];
}

export const Container = styled.div`
  & > div {
    border-bottom: 1px solid var(--color-grey-3);
  }

  & > div:last-child {
    border-bottom: none;
  }
`;

export const TransactionList: React.FC<Props> = ({ transactions }: Props) => {
  function getMerchant(transaction: Transactions): String {
    return transaction.flow_type === 'received_credit'
      ? transaction.description.split('|')[0]
      : transaction.description.split('|')[2];
  }

  function getKey(index: number, transaction: Transactions): string {
    const merchant = getMerchant(transaction);
    const date = new Date(transaction.created * 1000);
    return `${index}${merchant}${date}`;
  }

  function getPreviousDate(index: number) {
    return index === 0
      ? new Date()
      : new Date(transactions[index - 1].created * 1000);
  }

  return (
    <Container>
      {transactions.map((transaction, index) => (
        <TransactionListRow
          key={getKey(index, transaction)}
          transaction={transaction}
          previousDate={getPreviousDate(index)}
        />
      ))}
    </Container>
  );
};
