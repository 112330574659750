import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { bankingApi } from "settings";
import { RootState } from "store";
import { Args } from "./financialAccountTypes";
import { financialAccountInitialState } from "./init";

export const fetchFinancialAccount = createAsyncThunk(
  "account/fetchFinancialAccount",
  async (args: Args) => {
    const response = await axios.get(
      `${bankingApi}/${args.connAcc}/financial_accounts/${args.finAcc}`,
      {
        headers: {
          Authorization: `Bearer ${args.token}`,
        },
      }
    );
    return response.data;
  }
);

const financialAccountSlice = createSlice({
  name: "financialAccount",
  initialState: financialAccountInitialState,
  reducers: {
    fetchFinancialAccount: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    // @ts-ignore
    [fetchFinancialAccount.pending]: (state) => {
      state.status = "loading";
    },
    // @ts-ignore
    [fetchFinancialAccount.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    // @ts-ignore
    [fetchFinancialAccount.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const financialAccountReducer = financialAccountSlice.reducer;

export const selectFinancialAccount = (state: RootState) =>
  state.financialAccount;
