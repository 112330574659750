import styled from 'styled-components';
import { Link as LinkNav } from 'react-router-dom';

export const NavLink = styled(LinkNav)`
    background: transparent;
    border: 1px solid transparent;
    borderRadius: 500px;
    color: var(--color-white);
    fontSize: 14px;
    height: 40px;
    letterSpacing: 1px;
    margin: 0 10px;
    padding: 8px 12px;
    textTransform: none;
    &:hover {
      textDecoration: none;
    },

`;

export const Link = styled.a`
  color: var(--color-grey-6);
  display: inline-block;
  margin: 0 5px;
  padding: 5px;
  text-decoration: none;
  font-size: var(--font-size-body-lg);
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
