import { FinancialAccountInitialState } from './financialAccountTypes';

export const financialAccountInitialState: FinancialAccountInitialState = {
  status: 'idle',
  error: null,
  data: {
    account_closed_reasons: [''],
    active_features: [''],
    balance: {
      cash: {
        available: {
          usd: 0,
        },
        inbound_pending: {
          usd: 0,
        },
        outbound_pending: {
          usd: 0,
        },
      },
      current: {
        usd: 0,
      },
      type: 'cash',
    },
    card_issuing: {
      access: '',
      restricted_reason: [''],
      status: '',
    },
    country: 'us',
    created: 0,
    financial_addresses: [
      {
        aba: {
          account_holder_name: '',
          account_number: '',
          account_number_last4: '',
          bank_name: '',
          routing_number: '',
        },
        supported_networks: [''],
      },
    ],
    id: '',
    inbound_flows: {
      access: '',
      restricted_reasons: [''],
      status: '',
    },
    livemode: false,
    object: '',
    outbound_flows: {
      access: '',
      restricted_reasons: [''],
      status: '',
    },
    restrictions: [''],
    status: 'open',
    supported_currencies: [''],
    treasury: {
      access: '',
      restricted_reasons: [''],
      status: '',
    },
  },
};
