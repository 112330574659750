import React from 'react';
import styled from 'styled-components';
import {
  CashIcon,
  ExclamationCircleIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/outline';

interface Props {
  merchant: string;
  description: string;
  amount: number;
  success: boolean;
  debit: boolean;
}

const Transaction: React.FC<Props> = ({
  merchant,
  description,
  amount,
  success,
  debit,
}: Props) => {
  return (
    <TransactionContainer>
      <LogoContainer success={success}>
        {success ? (
          <>
            {debit ? (
              <CashIcon className='tr-icon' />
            ) : (
              <SwitchHorizontalIcon className='tr-icon' />
            )}
          </>
        ) : (
          <ExclamationCircleIcon className='tr-icon' />
        )}
      </LogoContainer>
      <TransactionDescriptionContainer>
        {merchant.includes('acct_1J0SnYQO86y3wsGa') ? (
          <>
            <Merchant>Paperchain</Merchant>
            <TransactionDescription>
              Daily Payments on {description}
            </TransactionDescription>
          </>
        ) : (
          <>
            <Merchant>{merchant}</Merchant>
            <TransactionDescription>{description}</TransactionDescription>
          </>
        )}
      </TransactionDescriptionContainer>
      <TransactionAmountContainer>
        <TransactionAmount debit={debit} success={success}>
          {debit ? '' : '-'}${Math.abs(amount).toFixed(2)}
        </TransactionAmount>
      </TransactionAmountContainer>
    </TransactionContainer>
  );
};

const Merchant = styled.p`
  font-size: 0.88rem;
  color: var(--color-black);
  display: inline-block;
  border-radius: 20px;
  left: 19%;
  top: 14%;
  margin: 0;
`;

interface LoginContainerProps {
  readonly success: boolean;
}

const LogoContainer = styled.div<LoginContainerProps>`
  background-color: ${(props) =>
    props.success ? 'var(--color-grey-2)' : 'var(--color-red-1)'};
  padding: 5px;
  border-radius: var(--border-radius-base);
  width: 48px;
  padding-top: 12px;
  height: 48px;
  display: inline-block;
  vertical-align: text-bottom;
  text-align: center;
  @media screen and (max-width: 525px) {
    height: 38px;
    padding: 7px;
    vertical-align: top;
    width: 38px;
  }
`;

const TransactionContainer = styled.div`
  min-height: 60px;
  padding: 10px 0;
`;

const TransactionDescription = styled.p`
  font-size: 14px;
  color: var(--color-grey-5);
  @media screen and (max-width: 525px) {
    font-size: 12px;
  }
`;

const TransactionDescriptionContainer = styled.div`
  padding: 0 10px;
  width: 60%;
  display: inline-block;
`;

interface AmountProps {
  readonly debit: boolean;
  readonly success: boolean;
}

const TransactionAmount = styled.p<AmountProps>`
  background-color: ${(props) =>
    props.debit && props.success ? 'var(--color-green-2)' : ''};
  border-radius: var(--border-radius-sm);
  color: ${(props) =>
    props.success
      ? props.debit
        ? 'var(--color-green-9)'
        : 'var(--color-black)'
      : 'var(--color-grey-5)'};
  float: right;
  font-size: var(--font-size-body);
  padding: 10px;
  text-decoration: ${(props) => (props.success ? 'none' : 'line-through')};
  @media screen and (max-width: 525px) {
    font-size: 14px;
  }
`;

const TransactionAmountContainer = styled.div`
  width: 22%;
  height: 100%;
  line-height: 28px;
  display: inline-block;
  text-align: center;
  float: right;
  clear: both;
  @media screen and (min-width: 500px) and (max-width: 850px) {
    width: 22%;
  }
  @media screen and (max-width: 500px) {
    width: 20%;

    p {
      font-size: 14px;
    }
  }
`;

export default Transaction;
