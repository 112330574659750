import React, { useState, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core/';
import { CheckIcon, EmojiSadIcon } from '@heroicons/react/outline';
import { ModalButtons } from 'Components';
import { Button } from 'app-components';
import {
  createACHTransfer,
  selectTransfers,
  selectFinancialAccount,
  fetchFinancialAccount,
  fetchTransactions,
  selectAccount,
  selectAccessToken,
} from 'features';
import { useAppDispatch, useAppSelector } from 'hooks';
import { sleep } from 'utils';

interface Props {
  amount: string;
  accountHolder: string;
  accountNumber: string;
  close: Dispatch<SetStateAction<boolean>>;
  routingNumber: string;
  setStage: Dispatch<SetStateAction<number>>;
  setHeader: Dispatch<SetStateAction<string>>;
}

const Transfer: React.FC<Props> = ({
  amount,
  accountHolder,
  accountNumber,
  close,
  routingNumber,
  setHeader,
  setStage,
}: Props) => {
  const financialAccount = useAppSelector(selectFinancialAccount);
  const transfer = useAppSelector(selectTransfers);
  const account = useAppSelector(selectAccount);
  const accessToken = useAppSelector(selectAccessToken);

  const dispatch = useAppDispatch();

  const [processing, setProcessing] = useState(false);
  const [next, setNext] = useState(false);

  const handleTransfer = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setProcessing(true);
    sleep(1000).then(async () => {
      const description = accountHolder.trimStart().split(' ')[0].slice(0, 10);

      await dispatch(
        createACHTransfer({
          accountId: account.data.account_id,
          transferData: {
            amount: parseFloat(amount) * 100,
            currency: 'usd',
            description: description,
            financial_account: financialAccount.data.id,
            network: 'ach',
            destination_data: {
              type: 'aba',
              aba: {
                account_holder_name: accountHolder,
                account_number: accountNumber,
                routing_number: routingNumber,
              },
            },
          },
        }),
      );
      setNext(true);
      setProcessing(false);
    });
  };

  const handleRetry = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setStage(1);
    setHeader('Send a payment');
  };

  return (
    <>
      {next ? (
        <>
          {transfer.status === 'failed' ? (
            <>
              {setHeader('Error')}
              <FailureMessage style={{ textAlign: 'center' }}>
                <EmojiSadIcon />
                <p>Unfortunately, we were unable to process your payment.</p>
              </FailureMessage>
              <ModalButtons>
                <Button
                  onClick={async () => {
                    close(false);
                    if (!accessToken) {
                      console.error(
                        'Failed to cancel transfer because access token was not in store',
                      );
                      return;
                    }
                    await dispatch(
                      fetchFinancialAccount({
                        connAcc: account.data.account_id,
                        finAcc: account.data.financial_account_id,
                        token: accessToken,
                      }),
                    );
                    await dispatch(
                      fetchTransactions({
                        connAcc: account.data.account_id,
                        finAcc: financialAccount.data.id,
                        token: accessToken,
                      }),
                    );
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleRetry}>Try again</Button>
              </ModalButtons>
            </>
          ) : (
            <>
              {setHeader('Payment sent')}
              <SuccessMessage style={{ textAlign: 'center' }}>
                <CheckIcon />
                <h1>${parseFloat(amount).toFixed(2)}</h1>
                <p>sent to {accountHolder}</p>
              </SuccessMessage>
              <ModalButtons>
                <Button></Button>
                <Button
                  onClick={async () => {
                    if (!accessToken) {
                      console.error(
                        'Failed to transfer because access token was not in store',
                      );
                      return;
                    }
                    await dispatch(
                      fetchFinancialAccount({
                        connAcc: account.data.account_id,
                        finAcc: account.data.financial_account_id,
                        token: accessToken,
                      }),
                    );
                    await dispatch(
                      fetchTransactions({
                        connAcc: account.data.account_id,
                        finAcc: financialAccount.data.id,
                        token: accessToken,
                      }),
                    );
                    close(false);
                  }}
                >
                  Close
                </Button>
              </ModalButtons>
            </>
          )}
        </>
      ) : (
        <>
          <Section>
            <P>Amount</P>
            <H1>${parseFloat(amount).toFixed(2)}</H1>
          </Section>
          <TransferInfo label='Account name' value={accountHolder} />
          <TransferInfo label='Account number' value={accountNumber} />
          <TransferInfo label='Routing number' value={routingNumber} />
          <ModalButtons>
            <Button onClick={() => setStage(1)}>Cancel</Button>
            <Button onClick={handleTransfer} disabled={processing}>
              {processing ? (
                <CircularProgress
                  style={{
                    color: '#fff',
                    height: '20px',
                    width: '20px',
                    margin: '0 5px 0 0',
                  }}
                />
              ) : (
                'Send'
              )}
            </Button>
          </ModalButtons>
        </>
      )}
    </>
  );
};

interface TransferInfoProps {
  label: string;
  value: string;
}

const TransferInfo: React.FC<TransferInfoProps> = ({
  label,
  value,
}: TransferInfoProps) => {
  return (
    <Section>
      <h5>{label}</h5>
      <P>{value}</P>
    </Section>
  );
};

const H1 = styled.h1`
  font-family: var(--font-family-mono);
  font-size: 36px;
  line-height: 25px;
  margin: 0;
`;

const P = styled.p`
  margin: 0 !important;
`;

const Section = styled.div`
  margin: 24px 0;
  h5 {
    font-size: 16px;
    margin: 0 !important;
  }
`;

const SuccessMessage = styled.div`
  margin: 40px 0;
  padding: 20px;
  svg {
    width: 70px;
    color: #fff;
    background-color: var(--color-green-6);
    border-radius: var(--border-radius-round);
  }
`;

const FailureMessage = styled(SuccessMessage)`
  svg {
    background-color: var(--color-red-err);
  }
`;
export default Transfer;
