export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE || '';
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const auth0SubDomain = process.env.REACT_APP_AUTH0_PC_SUB_DOMAIN || '';
export const environment = process.env.NODE_ENV;
export const heapKey = process.env.REACT_APP_HEAP_KEY || '';
export const iconPath = process.env.REACT_APP_ICON_PATH || '';
export const intercomId = process.env.REACT_APP_INTERCOM_ID || '';
export const puraApi = process.env.REACT_APP_PURA_API || '';
export const stripeApi = process.env.REACT_APP_STRIPE_API || '';
export const stripeKey = process.env.REACT_APP_STRIPE_API_KEY || '';
export const stripeVersion = process.env.REACT_APP_STRIPE_API_VERSION || '';
export const stripeIssuingKeyUrl =
  process.env.REACT_APP_STRIPE_ISSUING_KEY_URL || '';
export const appIconPath = process.env.REACT_APP_ICON_PATH || '';
export const notificationsApi = process.env.REACT_APP_NOTIFICATION_API || '';
export const bankingApi = process.env.REACT_APP_BANKING_API || '';
export const remixApi = process.env.REACT_APP_REMIX_API || '';
