interface Props {
  children: number;
  isCents?: boolean;
}

export default function Balance({ isCents, children: balance }: Props) {
  const centsValue = isCents ? balance / 100 : balance;
  const value = centsValue.toFixed(2);

  return <span>${value}</span>;
}
